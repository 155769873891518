// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
    display: flex;
    margin-left: -25px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 25px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: transparent;
    margin-bottom: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/BaseUi/UiCards/masonry.css"],"names":[],"mappings":"AAAA,mBAC0B,gCAAgC,EAChC,gCAAgC;IACtD,aAAa;IACb,kBAAkB,EAAE,uBAAuB;IAC3C,WAAW;EACb;EACA;IACE,kBAAkB,EAAE,gBAAgB;IACpC,4BAA4B;EAC9B;;EAEA,qBAAqB;EACrB,gCAAgC,+DAA+D;IAC7F,uBAAuB;IACvB,mBAAmB;EACrB","sourcesContent":[".my-masonry-grid {\r\n    display: -webkit-box; /* Not needed if autoprefixing */\r\n    display: -ms-flexbox; /* Not needed if autoprefixing */\r\n    display: flex;\r\n    margin-left: -25px; /* gutter size offset */\r\n    width: auto;\r\n  }\r\n  .my-masonry-grid_column {\r\n    padding-left: 25px; /* gutter size */\r\n    background-clip: padding-box;\r\n  }\r\n  \r\n  /* Style your items */\r\n  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */\r\n    background: transparent;\r\n    margin-bottom: 20px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
