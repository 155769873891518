import React, { useEffect, useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";

import {
  Col,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import FotoModal from "../../assets/images/telkom/modal.png";
import mulaiRekam from "../../assets/images/telkom/mulaiRekam.svg";
import stopRekam from "../../assets/images/telkom/stopRekam.svg";
// import ReactPlayer from 'react-player'
// import VideoRecorder from 'react-video-recorder'
// import { useReactMediaRecorder } from "react-media-recorder";
import axios from "axios";

const P4 = () => {
  document.title = "Trial Device";

  if (sessionStorage.getItem('user') == null) {
    window.location.href = '/login-perserta';
  }

  // -------- WEBCAM -------- //
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [disablerPreview, setDisablerPreview] = useState(true);
  const [disablerChoose, setDisablerChoose] = useState(false);

  const [modalPermission, setModalPermission] = useState(false);
  const openModalPermission = () => {
    setModalPermission(!modalPermission);
  };

  const checkDevice = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    } catch (error) {
      openModalPermission()
    }
  }

  const handleStartCaptureClick = useCallback(() => {
    checkDevice();

    try{
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
      setCapturing(true);
      setDisablerChoose(true); 

    } catch {
      alert("Device Kamera Anda Tidak Tersedia, Silahkan Refresh Page Ini")
    }


  }, [setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    try {
      mediaRecorderRef.current.stop();
      setCapturing(false);
      setDisablerPreview(false);
      setDisablerChoose(false);
    } catch {
      alert("Device Kamera Anda Tidak Tersedia, Silahkan Refresh Page Ini")
    }

  }, [mediaRecorderRef, setCapturing]);

  const handlePlay = useCallback(() => {

    if (recordedChunks.length) {
      var superBuffer = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      setFileInfo(window.URL.createObjectURL(superBuffer));
    }
  });

  // ---------- ON NEXT --------- //
  const [step, setStep] = React.useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  // ~~~~~~~~ ON NEXT ~~~~~~~~~ //

  // --------- MODAL ---------- //
  const [modalStartTrial, setModalStartTrial] = useState(false);
  const openModalStartTrial = () => {
    setModalStartTrial(!modalStartTrial);
  };

  const [cameras, setCameras] = useState([]);
  const [mics, setMics] = useState([]);
  const [selectedCameraId, setSelectedCameraId] = useState("");
  const [selectedMicId, setSelectedMicId] = useState("");

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices()
      .then(function (devices) {
        const cameraList = devices.filter((device) => device.kind === "videoinput");
        if(cameraList[0].deviceId != "") {
          setCameras(cameraList);
        }
      })
      .catch(function (err) {
        openModalPermission()

        console.error("❌ Terjadi kesalahan:", err);

        if (err.name === "NotAllowedError") {
          alert("❗ Akses kamera ditolak. Silakan izinkan kamera di pengaturan browser.");
        } else if (err.name === "NotFoundError") {
          alert("🚫 Kamera tidak ditemukan.");
        } else if (err.message === "🚫 Tidak ada kamera yang terdeteksi!") {
          alert("⚠️ Tidak ada kamera yang tersedia.");
        } else {
          alert("⚠️ Terjadi kesalahan yang tidak diketahui.");
        }
      });

    navigator.mediaDevices.enumerateDevices()
      .then(function (devices) {
        const audioList = devices.filter((device) => device.kind === "audioinput")
        if(audioList[0].deviceId != "") {
          setMics(audioList)
        }
      })
      .catch(function (err) {
        openModalPermission()
        console.error("❌ Terjadi kesalahan:", err);

        if (err.name === "NotAllowedError") {
          alert("❗ Akses kamera ditolak. Silakan izinkan kamera di pengaturan browser.");
        } else if (err.name === "NotFoundError") {
          alert("🚫 Kamera tidak ditemukan.");
        } else if (err.message === "🚫 Tidak ada kamera yang terdeteksi!") {
          alert("⚠️ Tidak ada kamera yang tersedia.");
        } else {
          alert("⚠️ Terjadi kesalahan yang tidak diketahui.");
        }
      });

      
  }, []);

  const handleChangeVideo = (event) => {
    setSelectedCameraId(event.target.value);
    sessionStorage.setItem("Camera", event.target.value)
  };

  const handleChangeAudio = (event) => {
    setSelectedMicId(event.target.value);
    sessionStorage.setItem("Mic", event.target.value)
  };

  const [devices, setDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <React.Fragment>
      <div className="page-content">
        {step === 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "25px",
                gap: "20px",
              }}
            >
              <Col xxl={4} md={4}>
                <div
                  style={{
                    marginTop: "55px",
                    width: "100%",
                    height: "200px",
                    border: "2px dashed #e5e5e5",
                    borderRadius: "12px",
                    padding: "20px",
                    color: "#2A4365",
                  }}
                >
                  <h5 style={{ color: "#2A4365" }}>
                    Silakan pastikan perangkat anda (audio & video) dalam
                    kondisi baik
                  </h5>
                </div>
              </Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "10px",
                }}
              >
                {capturing ? (
                  <div
                    style={{
                      width: "140px",
                      backgroundColor: "#FFF5F5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      border: "1px solid #E53E3E",
                    }}
                    onClick={handleStopCaptureClick}
                  >
                    <img style={{ width: "15px" }} src={stopRekam} />
                    <p
                      style={{
                        margin: "0",
                        color: "#000000",
                        fontWeight: "700",
                      }}
                    >
                      Stop Rekam
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "140px",
                      backgroundColor: "#F0FFF4",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      border: "1px solid #48BB78",
                    }}
                    onClick={handleStartCaptureClick}
                  >
                    <img style={{ width: "20px" }} src={mulaiRekam} />
                    <p
                      style={{
                        margin: "0",
                        color: "#000000",
                        fontWeight: "700",
                      }}
                    >
                      Mulai Rekam
                    </p>
                  </div>
                )}
                <Webcam
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
                  }}
                  width="480"
                  height="360"
                  audioConstraints={{ echoCancellation: false, deviceId: selectedMicId }}
                  videoConstraints={{ deviceId: selectedCameraId }}
                  audio={true}
                  ref={webcamRef}
                  muted={true}
                />

                {/* {recordedChunks.length > 0 && (
                <>
                <button onClick={handleDownload}>Download</button>
                <button onClick={handlePlay}>Play</button>
                </>
              )} */}
                {console.log(cameras)}
                {cameras.length == 0 || mics.length == 0 ? (
                  <>
                    {cameras.length == 0 ? (<p style={{color: "#E53E3E", margin: "0px"}}>Device Kamera anda tidak mendapatkan izin</p>) : (<p style={{color: "#48bb78", margin: "0px"}}>Device Kamera anda sudah mendapatkan izin</p>)}
                    {mics.length == 0 ? (<p style={{color: "#E53E3E", margin: "0px"}}>Device Mikrofon anda tidak mendapatkan izin</p>) : (<p style={{color: "#48bb78", margin: "0px"}}>Device Mikrofon anda sudah mendapatkan izin</p>)}
                    <p style={{fontSize: "18px", fontWeight: "bold", color: "#2a4365"}}>Silahkan Reload Halaman Anda</p>
                  </>
                ) : (
                  <>
                  
                  <label>Pilih Webcam:</label>
                  <select
                    value={selectedCameraId}
                    onChange={(event) => {
                      handleChangeVideo(event);
                    }}
                    disabled={disablerChoose}
                  >
                    {cameras.map((camera) => (
                      <option key={camera.deviceId} value={camera.deviceId}>
                        {camera.label}
                      </option>
                    ))}
                  </select>


                  <label>Pilih Microphone:</label>
                  <select
                    value={selectedMicId}
                    onChange={(event) => {
                      handleChangeAudio(event);
                    }}
                    disabled={disablerChoose}
                  >
                    {mics.map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                      </option>
                    ))}
                  </select>

                  <Button
                    style={{
                      padding: "0.5rem 3.0rem",
                      fontWeight: "700",
                      borderRadius: "6px",
                      float: "right",
                    }}
                    className="btn btn-success"
                    onClick={() => {
                      onNext();
                      handlePlay();
                    }}
                    disabled={disablerPreview}
                  >
                    Pratinjau
                  </Button>

                  </>
                )}
              
              </div>
            </div>


            {/* <div ref={screenRef}>
              
              <p>Hai :D</p>
              <button onClick={handleScreenshot}>Take Screenshot</button>
              {screenshot && <img src={screenshot} />}
            </div> */}
          </>
        )}

        {step === 1 && (
          <>
            <div
              style={{
                padding: "30px 1px",
                margin: "0px 20px",
                display: "flex",
                justifyContent: "center",
                border: "1px solid #e5e5e5",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            >
              <video
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
                  width: "480px",
                  height: "360px",
                }}
                src={fileInfo}
                playsInline
                controls
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px 20px",
              }}
            >
              <Button
                style={{
                  padding: "0.5rem 3.0rem",
                  backgroundColor: "#ffffff",
                  color: "#718096",
                  border: "1px solid #A0AEC0",
                  fontWeight: "700",
                  borderRadius: "6px",
                }}
                className="btn btn-light"
                onClick={() => {
                  onPrevious();
                  setFileInfo(null);
                  setRecordedChunks([]);
                }}
              >
                Rekam Ulang
              </Button>
              <Button
                style={{
                  padding: "0.5rem 3.0rem",
                  fontWeight: "700",
                  borderRadius: "6px",
                }}
                className="btn btn-success"
                onClick={() => {
                  openModalStartTrial();
                }}
              >
                Mulai Trial Interview
              </Button>
            </div>
          </>
        )}
      </div>

      
      <Modal
        isOpen={modalPermission}
        // toggle={() => {
        //   openModalPermission();
        // }}
        centered
      >
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h5 style={{ textAlign: "center", marginTop: "12px" }}>
            Silahkan check perizinan Device Kamera dan Microphone Anda
          </h5>
          <p style={{ textAlign: "center", marginBottom:'8px', marginTop:'18px' }}>
          Coba reset izin kamera dan mikrofon (Allow) di pengaturan browser:
          </p>
          <span style={{ fontWeight: "800" }}>
              Google Chrome : Settings → Privacy & Security → Site Settings → Camera & Microphone
            </span>
            <span style={{ fontWeight: "800" }}>
              Mozilla Firefox : Settings → Privacy & Security → Permissions
            </span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "300px",
              marginTop: "10px",
            }}
          >
            <Button
              style={{
                padding: "0.5rem 3.0rem",
                fontWeight: "700",
                borderRadius: "6px",
              }}
              className="btn btn-success"
              onClick={() => {
                window.location.reload()
              }}
            >
              Reload
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalStartTrial}
        toggle={() => {
          openModalStartTrial();
        }}
        centered
      >
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img style={{ marginTop: "15px" }} src={FotoModal} />
          <h5 style={{ textAlign: "center", marginTop: "30px" }}>
            Anda Akan Memulai Trial Interview
          </h5>
          <p style={{ padding: "0 70px", textAlign: "center" }}>
            Sebelum melakukan interview yang sebenarnya, Anda dipersilakan
            melakukan trial interview terlebih dahulu.{" "}
            <span style={{ fontWeight: "800" }}>
              Pastikan perangkat dalam kondisi baik.
            </span>
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "300px",
              marginTop: "10px",
            }}
          >
            <Button
              style={{
                padding: "0.5rem 3.0rem",
                backgroundColor: "#ffffff",
                color: "#718096",
                border: "1px solid #A0AEC0",
                fontWeight: "700",
                borderRadius: "6px",
              }}
              className="btn btn-light"
              onClick={() => {
                setModalStartTrial(false);
              }}
            >
              Tutup
            </Button>
            <Button
              style={{
                padding: "0.5rem 3.0rem",
                fontWeight: "700",
                borderRadius: "6px",
              }}
              className="btn btn-success"
              onClick={() => {
                window.location.href = "/trial";
              }}
            >
              Mulai
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default P4;
