import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Container, Row, Input, Label, Button, Modal, ModalBody, ModalFooter, Alert } from "reactstrap";
import { useInterval } from "usehooks-ts";
import axios from "axios";

import Webcam from "react-webcam";
import mulaiRekam from "../../assets/images/telkom/mulaiRekam.svg";
import stopRekam from "../../assets/images/telkom/stopRekam.svg";
import Timeout from "../../assets/images/telkom/timeout.svg";
import Loading from "../../assets/images/telkom/loadingred.gif";
import DraftPenilaian from "../../assets/images/telkom/penilaian.svg";

import success from "../../assets/images/telkom/success.svg";
import CryptoJS from "crypto-js";


const Soal = () => {
  document.title = "Interview";

  const [check, setCheck] = useState(true);
  const [kode, setKode] = useState(0);

  const [isiFeedback, setIsiFeedback] = useState("");

  // -- Alert -- //
  const [timeoutVideo, setTimeoutVideo] = useState(true);
  const [timeoutWaktu, setTimeoutWaktu] = useState(true);

  // ----- WEBCAM ---- //
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [uploadMotionFile, setUploadMotionFile] = useState([]);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [disablerPreview, setDisablerPreview] = useState(true);
  const [selectedCameraId, setSelectedCameraId] = useState(sessionStorage.getItem("Camera"));
  const [selectedMicId, setSelectedMicId] = useState(sessionStorage.getItem("Mic"));

  const [judulHeader, setJudulHeader] = useState("");
  const [jawabanya, setJawabanya] = useState([]);

  const [inputFields, setInputFields] = useState([]);
  const [ambilIndexJawaban, setAmbilIndexJawaban] = useState("0");

  const [count, setCount] = useState(0);
  const [step, setStep] = useState(0);

  const [masukinFile, setMasukinFile] = useState("");
  const [disablerVideo, setDisablerVideo] = useState(false);

  // ----- TIMER ---- //
  const Ref = useRef(null);
  const RefSoal = useRef(null);
  const [timer, setTimer] = useState("00:00:00");
  const [time, setTime] = useState("");

  const [timerSoal, setTimerSoal] = useState("00:00");
  const [timeSoal, setTimeSoal] = useState("");

  // ------- PROSESS UPLOAD --------- //
  const [detikChecking, setDetikChecking] = useState(1000);
  const [checking, setChecking] = useState(false);
  const [prosessGimana, setProsessGimana] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  // ------- CHECK SELESAI ------ //
  const [menghilang, setMenghilang] = useState("");
  const [modalSelesai, setModalSelesai] = useState(false);


  const [feedCW, setFeedCW] = useState("");


  // ------ LOCAL STORAGE ------ //

  // if (localStorage.getItem('user') == null) {
  //   window.location.href = '/login-peserta';
  // }
  if (sessionStorage.getItem("user") == null) {
    window.location.href = "/login-perserta";
  }

  const auth = JSON.parse(sessionStorage.getItem("user"));
  // const auth = JSON.parse(localStorage.getItem('user'))
  const id = auth.id;
  const idEvent = auth.id_Event;
  const namaInterviewer = auth.nama;

  const checkSelesai = () => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/pesertaEvent/${id}/${idEvent}`,
      headers: { 
        'x-api-key': auth.token
      }
    };

    axios(config)
      .then(function (response) {
        if (response.data[0].status === "selesai") {
          setModalSelesai(true);
          setMenghilang("none");
          sessionStorage.removeItem("user");
          setTimeout(() => {
            window.location.href = "/login-perserta";
          }, 10000);
        }
      })
      .catch(function (error) {
        setCheck(false);

        openModalContactCenter();
        // console.log("peserta/pesertaEvent/id/idEvent", error);
        console.log("1")
      });
  };

  // -------- WEB CAM ------- //
  const handleStartCaptureClick = useCallback(() => {
    setDisablerVideo(true);
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setDisablerPreview(false);
  }, [mediaRecorderRef, setCapturing]);

  const handlePlay = useCallback(() => {
    if (recordedChunks.length) {
      var superBuffer = new Blob(recordedChunks, {
        type: "video/mp4",
      });
    }
    setFileInfo(window.URL.createObjectURL(superBuffer));
  });

  const [uploadProsess, setUploadProsess] = useState(null);

  const handleDownload = useCallback((index) => {
      setUploadProsess(false);
      const fileName = `jawaban-${id}-${idEvent}-${ambilIndexJawaban}-${namaInterviewer}.mp4`

      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: "video/mp4",
        });
        // const url = URL.createObjectURL(blob);
        const file = new File(
          [blob],
          fileName,
          {
            type: "video/mp4",
          }
        );

        const FormData = require("form-data");
        const data = new FormData();
        data.append("file", file);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/uploadVideoStt`,
          data: data,
          headers: {
            "x-api-key" : auth.token,
            'Content-Type': 'multipart/form-data'
          }
        };

        axios(config)
          .then(function (response) {
            if (response.status === 200) {
              setCheck(true);
              const ganti = [...inputFields];
              ganti[index].file = response.urlFile;
              ganti[index].jawab = response.message;
              ganti[index].namaVideoJawab = response.fileName;
              ganti[index].note = response.message;
              setInputFields(ganti);

              // const tanggalNow = new Date();
              // const tahun = tanggalNow.getFullYear();
              // const bulan = tanggalNow.getMonth() + 1;
              // const hari = tanggalNow.getDate();

              const data = JSON.stringify({
                id: id,
                id_Event: idEvent,
                // tanggal_Submit: `${tahun}-${bulan}-${hari}`,
                batas_Waktu: timer,
                soal: JSON.stringify(inputFields),
              });

              const config = {
                method: "patch",
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/jawab/progress`,
                headers: {
                  "Content-Type": "application/json",
                  'x-api-key': auth.token
                },
                data: data,
              };

              axios(config)
                .then(function (response) {
                  if (response.status === 200) {
                    setCheck(true);
                    setUploadProsess(true);
                  } else {
                    setCheck(false);
                    openModalContactCenter();
                  }
                })
                .catch(function (error) {
                  setCheck(false);
                  openModalContactCenter();
                  // console.log("peserta/jawab/progress", error);
                });
            } else {
              setCheck(false);
              openModalContactCenter();
            }
          })

          .catch(function (error) {
            setCheck(false);
            openModalContactCenter();
            // console.log("/peserta/uploadVideoStt", error);
          });

        // const a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style = "display: none";
        // a.href = url;
        // a.download = `${id}-${idEvent}-${ambilIndexJawaban}-${namaInterviewer}.mp4`;
        // a.click();
        // handleChangeFile(url)
        // window.URL.revokeObjectURL(url);
        setRecordedChunks([]);
      }
    },
    [recordedChunks]
  );

  const handleDownloadEnd = useCallback(
    (index) => {
      const fileName = `jawaban-${id}-${idEvent}-${ambilIndexJawaban}-${namaInterviewer}.mp4`
      setUploadProsess(false);
      endTimer();
      checkProses();
      setChecking(true);

      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: "video/mp4",
        });
        // const url = URL.createObjectURL(blob);
        const file = new File(
          [blob],
          fileName,
          {
            type: "video/mp4",
          }
        );

        const FormData = require("form-data");
        const data = new FormData();
        data.append("file", file);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/uploadVideoStt`,
          data: data,
          headers: {
            "x-api-key" : auth.token,
            'Content-Type': 'multipart/form-data'
          }
        };

        axios(config)
          .then(function (response) {
            if (response.status === 200) {
              setCheck(true);

              const ganti = [...inputFields];
              ganti[index].file = response.urlFile;
              ganti[index].namaVideoJawab = response.fileName;
              ganti[index].jawab = response.message;
              ganti[index].note = response.message;
              setInputFields(ganti);

              // const tanggalNow = new Date();
              // const tahun = tanggalNow.getFullYear();
              // const bulan = tanggalNow.getMonth() + 1;
              // const hari = tanggalNow.getDate();

              const data = JSON.stringify({
                id: id,
                id_Event: idEvent,
                // tanggal_Submit: `${tahun}-${bulan}-${hari}`,
                batas_Waktu: timer,
                soal: JSON.stringify(inputFields),
              });

              const config = {
                method: "patch",
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/jawab/end`,
                headers: {
                  "Content-Type": "application/json",
                  'x-api-key': auth.token
                },
                data: data,
              };

              axios(config)
                .then(function (response) {
                  if (response.status === 200) {
                    setCheck(true);
                    //openModalContactCenter();
                    setUploadProsess(true);
                  } else {
                    setCheck(false);
                    openModalContactCenter();
                  }
                })
                .catch(function (error) {
                  setCheck(false);
                  openModalContactCenter();
                  // console.log("/peserta/jawab/end", error);
                });
            } else {
              setCheck(false);
              openModalContactCenter();
            }
          })
          .catch(function (error) {
            // openModalContactCenter();
            setCheck(false);
            openModalContactCenter();
            // console.log("/peserta/uploadVideoStt", error);
          });

        // const a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style = "display: none";
        // a.href = url;
        // a.download = `${id}-${idEvent}-${ambilIndexJawaban}-${namaInterviewer}.mp4`;
        // a.click();
        // handleChangeFile(url)
        // window.URL.revokeObjectURL(url);
        setRecordedChunks([]);
      }
    },
    [recordedChunks]
  );

  useEffect(() => {

    const user = JSON.parse(sessionStorage.getItem("user"));
  
    if (user) {
      getSoalById(false);
      getJudulInterview(user.token, user.id_Event);
      getFeedbackCW(user.token)
    }

    // window.onbeforeunload = function() {
    //   return "Dude, are you sure you want to leave? Think of the kittens!";
    // }

    // clearTimer(getDeadTime());
  }, []);

  // ------ GET JUDUL INTERVIEW ----- //
  const getJudulInterview = (token, id_Event) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/pesertaJudul/${id_Event}`,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {
        setJudulHeader(
          JSON.parse(JSON.stringify(response.data)).judul_Interview
        );
      })
      .catch(function (error) {
        setCheck(false);
        openModalContactCenter();
        // console.log("/peserta/pesertaJudul/idEvent", error);
      });
  };

  // ------ GET SOAL INTERVIEW ------ //
  const getSoalById = (boolean) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/${id}/${idEvent}`,
      headers: { 
        'x-api-key': auth.token
      }
    };

    axios(config)
      .then(function (response) {
        // console.log(response.soal)

        const decrypt = CryptoJS.AES.decrypt(response.soal.toString(CryptoJS.enc.Base64), CryptoJS.enc.Utf8.parse(process.env.REACT_APP_E_D), { mode: CryptoJS.mode.ECB, iv: CryptoJS.enc.Utf8.parse('test')});

        const soalgan = JSON.parse(JSON.parse(JSON.stringify(decrypt.toString(CryptoJS.enc.Utf8))));
        // console.log(soalgan)
        const timegan = JSON.parse(JSON.stringify(response.batasWaktu));

        setTime(timegan);

        const a = timegan.split(":");
        const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];


        
        if (boolean == true) { // TRUE UNTUK UPDATE DURASI VIDEO SOAL

          const soalnya = [...soalgan]
          const typePengantar = soalnya.map((item) => item.type === "pengantar" ? true : false);
          const typeVideo = soalnya.map((item) => item.type === "video&video" ? true : false);
      
          const namaBuket = process.env.REACT_APP_BUCKET_NAME
          if (typePengantar.indexOf(true) !== -1) {
              const data = JSON.stringify({
                  "bucketName": namaBuket,
                  "fileName": soalnya[typePengantar.indexOf(true)].namaVideoSoal,
                  "exp": seconds
              });
      
              const config = {
                  method: 'patch',
                  url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getFile`,
                  headers: {
                      'Content-Type': 'application/json',
                      'x-api-key': auth.token
                  },
                  data: data
              };
      
              axios(config)
                  .then(function (response) {
                      soalnya[typePengantar.indexOf(true)].file = response.data
                  })
                  .catch(function (error) {
                      // console.log("/peserta/getFile", error);
                      console.log("1")
                  });
      
              if (typeVideo.indexOf(true) !== -1) {
      
                  let filteredArray = soalnya.filter((item, index) => item.type === "video&video");
                  let indexArray = filteredArray.map(item => soalnya.indexOf(item));
      
                  for (let index = 0; index < indexArray.length; index++) {
                      
                      const data = JSON.stringify({
                          "bucketName": namaBuket,
                          "fileName": soalnya[indexArray[index]].namaVideoSoal,
                          "exp": seconds
                      });
      
                      const config = {
                          method: 'patch',
                          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getFile`,
                          headers: {
                              'Content-Type': 'application/json',
                              'x-api-key': auth.token
                          },
                          data: data
                      };
      
                      axios(config)
                          .then(function (response) {
                              soalnya[indexArray[index]].tanya = response.data
                              // setModalWarning(true)
                          })
                          .catch(function (error) {
                              // console.log("/peserta/getFile", error);
                              console.log("1")
                          });
                  }
      
              } 
              // else {
              //     setModalWarning(true)
              // }
      
          } else {
      
              if (typeVideo.indexOf(true) !== -1) {
      
                  let filteredArray = soalnya.filter((item, index) => item.type === "video&video");
                  let indexArray = filteredArray.map(item => soalnya.indexOf(item));
      
                  for (let index = 0; index < indexArray.length; index++) {
      
                      const data = JSON.stringify({
                          "bucketName": namaBuket,
                          "fileName": soalnya[indexArray[index]].namaVideoSoal,
                          "exp": seconds
                      });
      
                      const config = {
                          method: 'patch',
                          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getFile`,
                          headers: {
                              'Content-Type': 'application/json',
                              'x-api-key': auth.token
                          },
                          data: data
                      };
      
                      axios(config)
                          .then(function (response) {
                              soalnya[indexArray[index]].tanya = response.data
                              // setModalWarning(true)
                          })
                          .catch(function (error) {
                              // console.log("/peserta/getFile", error);
                              console.log("1")
                              // setModalWarning(true)
                          });
                  }
      
              } 
              // else {
              //     setModalWarning(true)
              // }
      
          }
          setInputFields(soalnya);
          // console.log(soalnya)
        } else { // TRUE UNTUK SUDAH DI UPDATE DURASI VIDEO SOAL

          setInputFields(soalgan);
        }


      })
      .catch(function (error) {
        setCheck(false);
        openModalContactCenter();
        // console.log("peserta/id/idEvent", error);
      });
  };

  // const updateSoal = () => {
  //   const data = JSON.stringify({
  //       "id": id,
  //       "soal": JSON.stringify(inputFields)
  //   });
  //   console.log(data)

  //   const config = {
  //       method: 'patch',
  //       url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/updateSoal`,
  //       headers: {
  //           'Content-Type': 'application/json',
  //           'x-api-key': auth.token
  //       },
  //       data: data
  //   };

  //   axios(config)
  //       .then(function (response) {
  //           console.log(response)
  //       })
  //       .catch(function (error) {
  //           console.log(error);
  //       });

  // }

  // ---------- Dynamic Form --------- //
  // const handleChangeJawab = (event, index) => {
  //   const ganti = [...inputFields]
  //   ganti[index].jawab = event.target.value
  //   setInputFields(ganti)
  // }
  // const handleChangeWaktu = (event, index) => {
  //   const ganti = [...inputFields]
  //   ganti[index].waktu = event.target.value
  //   setInputFields(ganti)
  // }
  // const handleChangeFile = (event, index) => {
  //   const ganti = [...inputFields];
  //   ganti[index].file = event;
  //   setInputFields(ganti);
  //   // console.log(ganti);
  // };
  const handleText = (event, index) => {
    const value = event.target.value.length;
    const ganti = [...inputFields];
    ganti[index].jawab = event.target.value;
    setInputFields(ganti);
    setCount(value);
  };

  // -------- SUBMIT JAWABAN ---------- //
  const submitJawaban = () => {
    // const tanggalNow = new Date();
    // const tahun = tanggalNow.getFullYear();
    // const bulan = tanggalNow.getMonth() + 1;
    // const hari = tanggalNow.getDate();

    const data = JSON.stringify({
      id: id,
      id_Event: idEvent,
      // tanggal_Submit: `${tahun}-${bulan}-${hari}`,
      batas_Waktu: timer,
      soal: JSON.stringify(inputFields),
    });

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/jawab/progress`,
      headers: {
        "Content-Type": "application/json",
        'x-api-key': auth.token
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setCheck(true);
        } else {
          setCheck(false);
          openModalContactCenter();
        }
      })
      .catch(function (error) {
        setCheck(false);
        openModalContactCenter();
        // console.log("/peserta/jawab/progress", error);
      });
  };
  const submitJawabanAkhir = () => {
    endTimer();
    setChecking(true);
    checkProses();
    setMenghilang("none")
  }

  const submitJawabanEnd = () => {
    // const tanggalNow = new Date();
    // const tahun = tanggalNow.getFullYear();
    // const bulan = tanggalNow.getMonth() + 1;
    // const hari = tanggalNow.getDate();
    // endTimer();
    // setChecking(true);
    // checkProses();

    const data = JSON.stringify({
      id: id,
      id_Event: idEvent,
      // tanggal_Submit: `${tahun}-${bulan}-${hari}`,
      batas_Waktu: timer,
      soal: JSON.stringify(inputFields),
    });

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/jawab/end`,
      headers: {
        "Content-Type": "application/json",
        'x-api-key': auth.token
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setCheck(true);
          openModalSuccess();


          setModalLoading(false);
          setChecking(false);
          openModalConfirm();

          setTimeout(() => {
            setModalSuccess(false);
          }, 1000);
        } else {
          setCheck(false);
          openModalContactCenter();
        }
      })
      .catch(function (error) {
        setCheck(false);
        openModalContactCenter();
        // console.log("/peserta/jawab/end", error);
      });
  };

  const checkProses = () => {
    const soalnya = [...inputFields];
    const typeJawab = soalnya.map((item) => item.type === "text&video" || item.type === "video&video" || item.type === "text&text" ? item.jawab !== "" ? true : "kena" : false);
    // console.log(typeJawab)
    const validasiTypeJawab = typeJawab.indexOf("kena");
    // console.log(validasiTypeJawab)

    if (validasiTypeJawab === -1 && uploadProsess) {
      setProsessGimana(true);
    } else {
      setProsessGimana(false);
    }
  };

  const [waktuLoading, setWaktuLoading] = useState(0);

  useInterval(
    () => {
      if (prosessGimana) {
        submitJawabanEnd();
      } else if (check === false && prosessGimana === false) {
        setModalLoading(false);
        openModalContactCenter();
        setChecking(false);
      } else {
        setModalLoading(true);
        checkProses();


        // Mengatasi Loading Terus-terusan
        setWaktuLoading(waktuLoading + 1);

        // PAKSA KIRIM JAWABAN
        if (waktuLoading === 6000) {
          setProsessGimana(true);
        }
      }
    },
    checking ? detikChecking : null
  );

  // ------ SUBMIT FEEDBACK ------- //
  const submitFeedback = () => {
    const data = JSON.stringify({
      id: id,
      id_Event: idEvent,
      feedback: isiFeedback,
    });

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/feedback`,
      headers: {
        "Content-Type": "application/json",
        'x-api-key': auth.token
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setCheck(true);
          setModalSuccess(true);
          window.location.href = "/login-perserta";
          setTimeout(() => {
            sessionStorage.removeItem("user");
          }, 10000)
        } else {
          setCheck(false);
          openModalContactCenter();
        }
      })
      .catch(function (error) {
        setCheck(false);
        openModalContactCenter();
        // console.log("/peserta/feedback", error);
      });
  };

  // --------- MODAL Confirm ---------- //
  const [modalConfirm, setModalConfirm] = useState(false);
  function openModalConfirm() {
    setModalConfirm(!modalConfirm);
  }
  const [modalMulai, setModalMulai] = useState(true);
  const [modalVideoEnd, setModalVideoEnd] = useState(false);
  const [modalEND, setModalEND] = useState(false);


  // --------- MODAL SUCCESS ---------- //
  const [modalSuccess, setModalSuccess] = useState(false);
  function openModalSuccess() {
    setModalSuccess(!modalSuccess);
  }

  // --------- MODAL DOWNLOAD ---------- //
  const [modalContactCenter, setModalContactCenter] = useState(false);
  function openModalContactCenter() {
    setModalContactCenter(!modalContactCenter);
  }
  // ~~~~~~~~ ON NEXT ~~~~~~~~~ //
  const onChange = (nextStep) => {
    setStep(
      nextStep < 0
        ? 0
        : nextStep > inputFields.length - 1
          ? inputFields.length - 1
          : nextStep
    );
  };
  const onNext = (index) => {
    onChange(step + 1);
    setCount(0);
    setDisablerPreview(true);
    setDisablerVideo(false);
    setTimerSoal([...inputFields][index + 1].waktu);
    setTimeoutVideo(true);
  };


  const [waktuVideo, setWaktuVideo] = useState(0);
  const [delay, setDelay] = useState(1000);
  const [isPlaying, setPlaying] = useState(false);
  // ---------- WAKTU JAWAB SOAL VIDEO ----------- //
  const getTime = (index) => {
    const ganti = [...inputFields];
    function angka() {
      let deadline = new Date();
      const a = ganti[index].waktu.split(":"); // split it at the colons
      const seconds = +a[0] * 60 + +a[1];
      setWaktuVideo(seconds);
      deadline.setSeconds(deadline.getSeconds() + seconds);
      return deadline;
    }
    clearTimerSoal(angka());
  };

  // Waktu Berjalan Sisa Waktu Soal Video
  useInterval(
    () => {
      setWaktuVideo(waktuVideo - 1);
      if (waktuVideo < 16) {
        setTimeoutVideo(false);
      }
      if (waktuVideo == 1) {
        setModalVideoEnd(true);
        setPlaying(false);
        handleStopCaptureClick();
      }
    },
    isPlaying ? delay : null
  );

  const stopWaktu = () => {
    if (RefSoal.current) clearInterval(RefSoal.current);
    const id = setInterval(() => {
      startTimerSoal();
    }, 0);
    RefSoal.current = id;
  };

  // const onClickResetSoal = () => {
  //   clearTimerSoal(getDeadTimeSoal());
  // }
  const clearTimerSoal = (e) => {
    if (RefSoal.current) clearInterval(RefSoal.current);
    const id = setInterval(() => {
      startTimerSoal(e);
    }, 1000);
    RefSoal.current = id;
  };

  const startTimerSoal = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemainingSoal(e);
    if (total >= 0) {
      // setTimer((hours > 9 ? hours : '0' + hours) + ':' +(minutes > 9 ? minutes : '0' + minutes) + ':'+ (seconds > 9 ? seconds : '0' + seconds))
      setTimerSoal(
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const getTimeRemainingSoal = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const [waktuInterview, setWaktuInterview] = useState(0);
  const [waktuBerjalan, setWaktuBerjalan] = useState(false);
  const [styleWaktu, setStyleWaktu] = useState({ display: "none" });
  const [winker, setWinker] = useState("");
  // ----------------------------- TIMER ------------------------------ //
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  // Waktu Berjalan Sisa Waktu
  useInterval(
    () => {
      setWaktuInterview(waktuInterview - 1);
      if (waktuInterview > 300) {
        setStyleWaktu({
          color: "#38A169",
          fontWeight: "700",
          padding: "2px 10px",
          backgroundColor: "#C6F6D5",
          borderRadius: "5px",
          marginRight: "30px",
        });
      } else if (waktuInterview > 1) {
        setStyleWaktu({
          color: "#D69E2E",
          fontWeight: "700",
          padding: "2px 10px",
          backgroundColor: "#FEFCBF",
          borderRadius: "5px",
          marginRight: "30px",
        });
        setWinker("wink");
      } else {
        setStyleWaktu({
          color: "#E53E3E",
          fontWeight: "700",
          padding: "2px 10px",
          backgroundColor: "#FED7D7",
          borderRadius: "5px",
          marginRight: "30px",
        });
        setWinker("");
        setTimeoutWaktu(false);
        setModalEND(true);
        setWaktuBerjalan(false);
        setChecking(true);
      }
    },
    waktuBerjalan ? delay : null
  );

  const clearTimer = (e) => {
    setTimer(time);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const endTimer = (e) => {
    setTimer(time);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 0);
    Ref.current = id;
  };
  const getDeadTime = () => {
    let deadline = new Date();
    const a = time.split(":");
    const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    setWaktuInterview(seconds);
    deadline.setSeconds(deadline.getSeconds() + seconds);
    return deadline;
  };
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
      // setTimerSoal((minutes > 9 ? minutes : '0' + minutes) + ':'+ (seconds > 9 ? seconds : '0' + seconds))
    }
  };
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  // ------- Breadcrumb ------- //
  function toggleActive(index) {
    if (index === step + 1) {
      return {
        color: "red",
        transition: "0.6s",
        padding: "10px 5px",
        fontWeight: "600",
      };
    } else {
      return {
        transition: "0.6s",
        padding: "10px 5px",
        color: "#A0AEC0",
        fontWeight: "600",
      };
    }
  }
  function toggleHidden(index) {
    if (index === step + 1) {
      return false;
    } else {
      return true;
    }
  }

  // ----- Text Count JAWAB MAX LENGTH 1500 ------ //
  function maxText(x) {
    if (x < 5) {
      return true;
    } else {
      return false;
    }
  }

  // ----- Text Count Feedback MAX LENGTH 1500 ------ //
  const [angkaFeedback, setAngkaFeedback] = useState(0);
  const changeFeedback = (event) => {
    const angka = event.target.value.length;
    const value = event.target.value;
    setIsiFeedback(value);
    setAngkaFeedback(angka);
  };
  function feedbackMati(x) {
    if (x < 5) {
      return true;
    } else {
      return false;
    }
  }


  const getFeedbackCW = (token) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getFeedbackCW`,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {
        setFeedCW(response.data[0].feedback)
      })
      .catch(function (error) {
        setCheck(false);
        openModalContactCenter();
        // console.log("/peserta/getFeedbackCW", error);
      });
  };

  const [start, setStart] = useState(false)


  return (
    <React.Fragment>
      <header style={{ backgroundColor: "#ffffff", display: menghilang }}>
        <h2 style={{ margin: "15px 50px", color: "#E53E3E", fontWeight: "700", fontSize: "28px" }} >
          {judulHeader}
        </h2>

        <header style={{ backgroundColor: "#F7FAFC", height: "40px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <p style={{ color: "#2D3748", marginBottom: "0px", marginLeft: "50px", fontSize: "14px", fontWeight: "800" }}>
              Section
            </p>
            <ol className="breadcrumb m-0">
              {inputFields.map((item, index) => (
                <li
                  key={index}
                  style={toggleActive(index + 1)}
                  value={index + 1}
                  className="breadcrumb-item"
                >
                  {index + 1}.
                  <span hidden={toggleHidden(index + 1)}> {item.type}</span>
                </li>
              ))}
            </ol>
          </div>
          <div className={winker} style={styleWaktu}>
            <p style={{ margin: "0px" }}>
              Sisa waktu akses online : <span>{timer}</span>
            </p>
          </div>
        </header>
      </header>

      <div style={{ marginTop: "25px", marginLeft: "30px", marginRight: "30px", display: menghilang, }}>
        {inputFields.map((item, index) => (
          <>
            {step === index && (
              <>
                {item.type === "pengantar" && (
                  <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
                          borderRadius: "8px",
                          width: "680px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "24px",
                            fontWeight: "600",
                            color: "#2A4365",
                            margin: "20px 0px",
                          }}
                        >
                          {item.tanya}
                        </p>

                        <video
                          style={{
                            width: "480px",
                            borderRadius: "8px",
                            marginBottom: "40px",
                          }}
                          controls
                        >
                          <source src={item.file} />
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "30px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {index === inputFields.length - 1 ? (
                        <>
                          <Button
                            style={{
                              padding: "0.5rem 3.0rem",
                              fontWeight: "700",
                              borderRadius: "6px",
                            }}
                            className="btn btn-success"
                            onClick={() => {
                              // submitJawabanEnd();
                              submitJawabanAkhir();
                              //check === false && openModalContactCenter()
                            }}
                          >
                            Selesai Interview
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            style={{
                              padding: "0.5rem 3.0rem",
                              fontWeight: "700",
                              borderRadius: "6px",
                            }}
                            className="btn btn-success"
                            onClick={() => {
                              onNext(index);
                              setTimeoutVideo(true);
                            }}
                          >
                            Mulai
                          </Button>
                        </>
                      )}
                    </div>
                  </>
                )}
                {item.type === "text&text" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col xxl={5} md={5}>
                        <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700", marginBottom: '10px' }}>
                          Pertanyaan :
                        </p>
                        <div
                          style={{
                            width: "100%",
                            height: "200px",
                            border: "2px dashed #e5e5e5",
                            borderRadius: "12px",
                            padding: "20px",
                            color: "#2A4365",
                          }}
                        >
                          <h5>{item.tanya}</h5>
                        </div>
                      </Col>
                      <Col xxl={6} md={6}>
                        <Alert
                          hidden={timeoutWaktu}
                          color="warning"
                          style={{
                            textAlign: "center",
                            backgroundColor: "#FFF3B5",
                            color: "#D88E00",
                            borderColor: "#FFF3B5",
                            marginBottom: "20px",
                            transition: "0.5s",
                            padding: "5px 0px",
                          }}
                        >
                          Waktu Anda telah habis! Silahkan submit jawaban anda!
                        </Alert>
                        <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700", marginBottom: '10px' }}>
                          Jawab :
                        </p>
                        <div className="mb-3">
                          <textarea
                            style={{
                              border: "none",
                              boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
                              borderRadius: "12px",
                              padding: "40px 30px",
                            }}
                            className="form-control"
                            placeholder="Ketik Jawaban Anda"
                            value={item.jawaban}
                            rows="10"
                            onChange={(event) => handleText(event, index)}
                            required
                            maxLength={1500}
                          />
                          <span style={{ color: "#505050" }}>
                            * Minimal 5 karakter
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ margin: "0px", fontWeight: "500" }}>
                            Sisa karakter: <span> {1500 - count}</span>
                          </p>
                          {index === inputFields.length - 1 ? (
                            <>
                              <Button
                                style={{
                                  padding: "0.5rem 3.0rem",
                                  fontWeight: "700",
                                  borderRadius: "6px",
                                }}
                                className="btn btn-success"
                                onClick={() => {
                                  // submitJawabanEnd();
                                  submitJawabanAkhir();
                                  //check === false && openModalContactCenter()
                                }}
                                disabled={maxText(count)}
                              >
                                Selesai
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                style={{ width: "120px" }}
                                color="success"
                                onClick={() => {
                                  submitJawaban();
                                  check === true
                                    ? onNext(index)
                                    : openModalContactCenter();
                                }}
                                disabled={maxText(count)}
                              >
                                Lanjut
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    </div>
                  </>
                )}
                {item.type === "text&video" && (
                  <>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "25px" }}>
                      <Col xxl={5} md={5}>
                        <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700", marginBottom: '10px' }}>
                          Pertanyaan :
                        </p>
                        <div style={{ width: "100%", height: "200px", border: "2px dashed #e5e5e5", borderRadius: "12px", padding: "20px", color: "#2A4365", marginTop: '70px' }}>
                          <h5 style={{ color: "#2A4365" }}>{item.tanya}</h5>
                        </div>
                      </Col>
                      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Alert hidden={timeoutWaktu} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "0px", transition: "0.5s", padding: "5px 0px" }} >
                          Waktu Anda telah habis! Silahkan submit jawaban anda!
                        </Alert>
                        <Alert hidden={timeoutVideo} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "0px", transition: "0.5s", padding: "5px 0px" }} >
                          Waktu menjawab soal Anda mendekati habis!
                        </Alert>
                        <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700", marginBottom: '10px' }}>
                          Jawab :
                        </p>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          {capturing ? (
                            <Button style={{ width: "140px", backgroundColor: "#FFF5F5", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 15px", borderRadius: "10px", cursor: "pointer", border: "1px solid #E53E3E", }}
                              onClick={() => {
                                handleStopCaptureClick();
                                setPlaying(false);
                                stopWaktu();
                              }}
                            >
                              <img style={{ width: "15px" }} src={stopRekam} />
                              <p style={{ margin: "0", color: "#000000", fontWeight: "700" }}>
                                Stop Rekam
                              </p>
                            </Button>
                          ) : (
                            <Button style={{ width: "140px", backgroundColor: "#F0FFF4", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 15px", borderRadius: "10px", cursor: "pointer", border: "1px solid #48BB78" }}
                              onClick={() => {
                                handleStartCaptureClick();
                                getTime(index);
                                setPlaying(true);
                                setAmbilIndexJawaban(index);
                              }}
                              disabled={disablerVideo}
                            >
                              <img style={{ width: "20px" }} src={mulaiRekam} />
                              <p style={{ margin: "0", color: "#000000", fontWeight: "700" }}>
                                Mulai Rekam
                              </p>
                            </Button>
                          )}
                        </div>

                        <Webcam
                          style={{
                            borderRadius: "10px",
                            boxShadow:
                              "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
                          }}
                          width="480"
                          height="360"
                          audio={true}
                          audioConstraints={{ echoCancellation: false, deviceId: selectedMicId }}
                          videoConstraints={{ deviceId: selectedCameraId }}
                          ref={webcamRef}
                          // audioConstraints={{ echoCancellation: false }}
                          muted={true}
                        />

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ margin: "0px", fontWeight: "500" }}>
                            Sisa waktu jawab: <span>{timerSoal}</span>
                          </p>
                          {index === inputFields.length - 1 ? (
                            <>
                              <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }} className="btn btn-success"
                                onClick={() => {
                                  handleDownloadEnd(index);
                                  //(check === false && openModalContactCenter())
                                }}
                                disabled={disablerPreview}
                              >
                                Selesai
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button className={index} style={{ width: "120px" }} color="success"
                                onClick={() => {
                                  handleDownload(index);
                                  check === true
                                    ? onNext(index)
                                    : openModalContactCenter();
                                }}
                                disabled={disablerPreview}
                              >
                                Lanjut
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {item.type === "video&video" && (
                  <>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "25px"}}>
                      <Col xxl={5} md={5}>
                        <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700", marginBottom: '10px' }}>
                          Pertanyaan :
                        </p>
                        <video style={{ borderRadius: "10px", boxShadow:"0px 2px 5px #0000001a, 0px 0px 2px #00000033", width: "480px", height: "360px", marginTop: '55px' }}
                          src={item.tanya}
                          playsInline
                          controls
                        />
                      </Col>
                      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Alert hidden={timeoutWaktu} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "0px", transition: "0.5s", padding: "5px 0px", }} >
                          Waktu Anda telah habis! Silahkan submit jawaban anda!
                        </Alert>
                        <Alert hidden={timeoutVideo} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "0px", transition: "0.5s", padding: "5px 0px" }}>
                          Waktu menjawab soal Anda mendekati habis!
                        </Alert>
                        <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700", marginBottom: '10px' }}>
                          Jawab :
                        </p>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          {capturing ? (
                            <Button style={{ width: "140px", backgroundColor: "#FFF5F5", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 15px", borderRadius: "10px", cursor: "pointer", border: "1px solid #E53E3E" }}
                              onClick={() => {
                                handleStopCaptureClick();
                                setPlaying(false);
                                stopWaktu();
                              }}
                            >
                              <img style={{ width: "15px" }} src={stopRekam} />
                              <p style={{ margin: "0", color: "#000000", fontWeight: "700" }}>
                                Stop Rekam
                              </p>
                            </Button>
                          ) : (
                            <Button style={{ width: "140px", backgroundColor: "#F0FFF4", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 15px", borderRadius: "10px", cursor: "pointer", border: "1px solid #48BB78" }}
                              onClick={() => {
                                handleStartCaptureClick();
                                getTime(index);
                                setPlaying(true);
                                setAmbilIndexJawaban(index);
                              }}
                              disabled={disablerVideo}
                            >
                              <img style={{ width: "20px" }} src={mulaiRekam} />
                              <p style={{ margin: "0", color: "#000000", fontWeight: "700" }}>
                                Mulai Rekam
                              </p>
                            </Button>
                          )}
                        </div>

                        <Webcam style={{ borderRadius: "10px", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033"}} width="480" height="360"
                          audio={true}
                          audioConstraints={{ echoCancellation: false, deviceId: selectedMicId }}
                          videoConstraints={{ deviceId: selectedCameraId }}
                          ref={webcamRef}
                         // audioConstraints={{ echoCancellation: false }}
                          muted={true}
                        />

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p style={{ margin: "0px", fontWeight: "500" }}>
                            Sisa waktu jawab: <span>{timerSoal}</span>
                          </p>
                          {index === inputFields.length - 1 ? (
                            <>
                              <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700",borderRadius: "6px" }} className="btn btn-success"
                                onClick={() => {
                                  handleDownloadEnd(index);
                                  // (check === false && openModalContactCenter())
                                }}
                                disabled={disablerPreview}
                              >
                                Selesai
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button className={index} style={{ width: "120px" }} color="success"
                                onClick={() => {
                                  handleDownload(index);
                                  check === true
                                    ? onNext(index)
                                    : openModalContactCenter();
                                }}
                                disabled={disablerPreview}
                              >
                                Lanjut
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </div>

      <Modal isOpen={modalEND} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
          <img src={Timeout} />
          <h5 style={{ textAlign: "center", marginTop: "30px", fontSize: "24px", fontWeight: "700", color: "#2A4365"}}>
            Maaf, waktu Anda habis!
          </h5>
          <p style={{ padding: "0 45px", textAlign: "center", fontSize: "16px", color: "#4A5568", fontWeight: "400", }} >
            Terima kasih atas partisipasinya. Mohon menunggu pengumuman kami
            lebih lanjut. Silahkan kirim jawaban anda!
          </p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px"}} className="btn btn-success"
            onClick={() => {
              submitJawaban();
              setModalEND(false);
              check === true ? openModalConfirm() : openModalContactCenter();
            }}
          >
            Kirim
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalMulai} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
          <h5 style={{ textAlign: "center", marginTop: "30px" }}>
            Silahkan kerjakan soal hingga selesai
          </h5>
          {/* <p style={{ padding: '0 70px', textAlign: 'center' }}>Apakah anda yakin untuk menghapus data ini?</p> */}
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          {/* <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-light" onClick={() => { openModalConfirm() }}>Batal</Button> */}
          
          {start == false ? (
            <>

              <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px"}} className="btn btn-success"
                    onClick={() => {
                      // setModalMulai(false); // ini buat matiin
                      // onClickReset(); // kayaknya buat nyalain Timernya
                      // setWaktuBerjalan(true); // ini juga buat nyalain Timernya versi fungsional FE
                      // setTimerSoal([...inputFields][index].waktu); // ini buat dapetin waktu Soal berapa lama durasinya
                      // checkSelesai(); // ini check ketika Pelamar sudah mengikuti atau menjawab Soal terlebih dahulu, JADI GA BOLEH IKUT KALO UDAH PERNAH WEB INTERVIEW
                      // UPDATE LINK DURASI VIDEO SOAL
                      
                      setStart(true)
                      // updateSoal()
                      getSoalById(true);
                    
                    }}
                  >
                    {" "}
                    Prepare
                  </Button>
            
            </>
          ) : (

            <>
            
            {inputFields.map((item, index) => (
            <>
              {step === index && (
                <>
                  <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px"}} className="btn btn-success"
                    onClick={() => {
                      setModalMulai(false); // ini buat matiin
                      onClickReset(); // kayaknya buat nyalain Timernya
                      setWaktuBerjalan(true); // ini juga buat nyalain Timernya versi fungsional FE
                      setTimerSoal([...inputFields][index].waktu); // ini buat dapetin waktu Soal berapa lama durasinya
                      checkSelesai(); // ini check ketika Pelamar sudah mengikuti atau menjawab Soal terlebih dahulu, JADI GA BOLEH IKUT KALO UDAH PERNAH WEB INTERVIEW
                      // UPDATE LINK DURASI VIDEO SOAL
                      // getSoalById(true);
                    
                    }}
                  >
                    {" "}
                    Mulai
                  </Button>
                </>
              )}
            </>
          ))}

            </>
          )}
          

        </ModalFooter>
      </Modal>

      <Modal isOpen={modalVideoEnd} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
          <h5 style={{ textAlign: "center", marginTop: "30px" }}>
            Maaf, Waktu Menjawab Soal ini telah habis
          </h5>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          {inputFields.map((item, index) => (
            <>
              {step === index && (
                <>
                  <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }} className="btn btn-success"
                    onClick={() => {
                      setModalVideoEnd(false);
                      setTimeoutVideo(true);
                    }}
                  >
                    Selanjutnya
                  </Button>
                </>
              )}
            </>
          ))}
        </ModalFooter>
      </Modal>

      {/* ------- MODAL Feedback------- */}
      <Modal isOpen={modalConfirm} toggle={() => { }} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div>
            <h5 style={{ textAlign: "center", marginTop: "30px", color: "#2A4365", fontWeight: "700", fontSize: "18px" }}>
              {feedCW}
            </h5>
          </div>

          <div style={{ marginTop: "10px", width: "100%" }}>
            {/* <Label style={{ color: "#2A4365", fontSize: "14px", fontWeight: "700" }} className="form-label">
              Feedback
            </Label> */}
            <Input
              value={isiFeedback}
              onChange={changeFeedback}
              type="text"
              className="form-control"
              placeholder="Tuliskan pendapat Anda ..."
              maxLength={250}
            />
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }}
            className="btn btn-success"
            onClick={() => {
              submitFeedback();
              check === true && openModalSuccess();
            }}
            disabled={feedbackMati(angkaFeedback)}
          >
            Kirim
          </Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL SUCCESS ---------- */}
      <Modal size="md" isOpen={modalSuccess}
        toggle={() => {
          openModalSuccess();
        }}
        centered
      >
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", margin: "90px 10px" }} >
          <img src={success} style={{ textAlign: "center" }} />
          <h5 style={{ textAlign: "center", margin: "20px" }}>
            Data Berhasil Dikirim
          </h5>
        </div>
      </Modal>

      {/* ------- MODAL LOADING ------- */}
      <Modal isOpen={modalLoading} toggle={() => { }} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
            <img src={Loading} style={{ width: "170px" }} />
            <h5 style={{ textAlign: "center", padding: "0 70px" }}>
              Tunggu Sebentar
            </h5>
          </div>
        </ModalBody>
      </Modal>

      {/* ------- MODAL CONFIRM ------- */}
      <Modal isOpen={modalSelesai} toggle={() => { }} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "40px" }}>
          <img src={DraftPenilaian} />
          <h5 style={{ textAlign: "center", marginTop: "30px", color: "#2A4365", fontSize: "24px", fontWeight: "700" }}>
            Anda sudah mengikuti rangkaian acara interview ini sebelumnya
          </h5>
          {/* <p style={{ padding: '0 70px', textAlign: 'center' }}>Apakah anda yakin untuk menghapus data ini?</p> */}
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }} className="btn btn-success"
            onClick={() => {
              window.location.href = "/login-perserta";
            }}
          >
            Baik
          </Button>
        </ModalFooter>
      </Modal>

      {/* ------- MODAL Contact Center ------- */}
      <Modal isOpen={modalContactCenter} toggle={() => { }} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "40px" }}>
          {/* <img src={DraftPenilaian} /> */}
          <h5 style={{ textAlign: "center", marginTop: "30px", color: "#2A4365", fontSize: "24px", fontWeight: "700" }} >
            Kode 404
          </h5>
          <p style={{ padding: "0 70px", textAlign: "center" }}>
            Silahkan Screenshot sebagai bukti dan hubungi Contact Center yang
            Tersedia
          </p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button style={{ padding: "0.5rem 3.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px" }} className="btn btn-light"
            onClick={() => {
              window.location.href = "/login-perserta";
            }}
          >
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Soal;
