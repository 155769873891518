import React, {useEffect} from "react";
import { Col, Container, Row } from "reactstrap";


const P1 = () => {
  document.title="Telkom ACI";

  // if (localStorage.getItem('user') == null) {
  //   window.location.href = '/login-peserta';
  // }
  if (sessionStorage.getItem('user') == null) {
    window.location.href = '/login-perserta';
  }

  useEffect(() => {
    // var number = document.getElementById('number');
    // number.onkeydown = (e) => {
    //     if(!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 8 || e.keyCode == 190 )) {
    //       return false;
    //     }
    //   }
  },[])

  // const pejet = (e) => {

  //   if(!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 8 || e.keyCode == 190 )) {
  //     return false;
  //   }
  // }


  return (
    <React.Fragment>

        <Row style={{marginTop: '100px', marginLeft: '50px', marginRight: '50px', borderRadius: '10px', boxShadow: '0px 2px 5px #0000001a, 0px 0px 2px #00000033'}}>
          <p style={{margin: '0', fontSize: '30px', fontWeight: '700', color: '#2A4365', padding: '20px 30px'}}>Syarat Peserta</p>
          <hr style={{color: '#b7b7b7'}}/>
          <Col>
          <ol style={{fontSize: '18px', fontWeight: '700', padding: '25px 0px 25px 40px'}}>
            <li><p style={{fontWeight: '500', margin:'0'}}>Peserta dapat berkomunikasi dengan baik.</p></li>
            <li><p style={{fontWeight: '500', margin:'0'}}>Peserta dapat bekerja secara profesional dan berkomitmen secara penuh.</p></li>
            <li><p style={{fontWeight: '500', margin:'0'}}>Peserta dapat berkerja secara sendiri maupun berkelompok.</p></li>
            <li><p style={{fontWeight: '500', margin:'0'}}>Berkelakuan baik, memiliki loyalitas tinggi, dan bersedia menaati semua peraturan yang berlaku.</p></li>
            <li><p style={{fontWeight: '500', margin:'0'}}>Berkomitmen melaksanakan program dalam jangka waktu yang telah ditetapkan.</p></li>
            <li><p style={{fontWeight: '500', margin:'0'}}>Bersedia apabila dibutuhkan untuk WFO(Work From Office).</p></li>
            <li><p style={{fontWeight: '500', margin:'0'}}>Pembelajaran yang cepat dan proaktif.</p></li>
            <li><p style={{fontWeight: '500', margin:'0'}}>Peserta memiliki pengetahuan atau keterampilan dasar tentang role yang di-apply.</p></li>
          </ol>
          </Col>
        </Row>

        {/* <h3>Peraturan Interview</h3>
        <ol>
          <li>Perserta <span style={{fontWeight: '800'}}>wajib</span> menggunakan komputer / laptop yang memiliki webcam atau kamera yang memadai</li>
          <li>Perserta <span style={{fontWeight: '800'}}>wajib</span> menyalakan webcam atau kamera selama proses interview berlangsung</li>
          <li>Perserta <span style={{fontWeight: '800'}}>perlu memastikan</span> koneksi internet stabil dan bebas gangguan</li>
          <li>Perserta <span style={{fontWeight: '800'}}>tidak diperkenankan</span> untuk membuka tab lain selain halaman website interview</li>
          <li>Perserta <span style={{fontWeight: '800'}}>diperbolehkan</span> untuk berlatih terlebih dahulu menggunakan fitur "trial" sebelum menjawab pertanyaan interview</li>
          <li>Perserta <span style={{fontWeight: '800'}}>wajib</span> menggunakan pakaian rapi dan sopan</li>
          <li>Perserta <span style={{fontWeight: '800'}}>diharapkan</span> untuk dapat memilih tempat yang tenang dan minim gangguan selama sesi interview</li>
          <li>Selama sesi interview berlangsung, perserta <span style={{fontWeight: '800'}}>tidak diperkenankan</span> untuk berbicara dengan orang lain, makan atau minum ataupun meninggalkan ruangan.</li>
        </ol> */}

    </React.Fragment>
  );
};

export default P1
;
