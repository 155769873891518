import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Col,
  Container,
  Row,
  Input,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Sambutan from "../../assets/images/telkom/sambutan.mp4";

import Webcam from "react-webcam";
import mulaiRekam from "../../assets/images/telkom/mulaiRekam.svg";
import stopRekam from "../../assets/images/telkom/stopRekam.svg";

import FotoModal from "../../assets/images/telkom/modal.png";

const Trial = () => {
  document.title = "Trial";

  // if (localStorage.getItem('user') == null) {
  //   window.location.href = '/login-peserta';
  // }
  if (sessionStorage.getItem("user") == null) {
    window.location.href = "/login-perserta";
  }

  const auth = JSON.parse(sessionStorage.getItem("user"));
  // const auth = JSON.parse(localStorage.getItem('user'))
  const id = auth.id;
  const idEvent = auth.id_Event;

  const [disablerPreview, setDisablerPreview] = useState(true);
  const [judulHeader, setJudulHeader] = useState("");

  // -------- WEBCAM -------- //
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [uploadMotionFile, setUploadMotionFile] = useState([]);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const [selectedCameraId, setSelectedCameraId] = useState(sessionStorage.getItem("Camera"));
  const [selectedMicId, setSelectedMicId] = useState(sessionStorage.getItem("Mic"));
  const [pembukaan, setPembukaan] = useState([]);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setDisablerPreview(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  // const handlePlay = useCallback(() => {
  //   if (recordedChunks.length) {
  //     var superBuffer = new Blob(recordedChunks, {
  //       type: "video/mp4",
  //     });
  //   }
  //   setFileInfo(window.URL.createObjectURL(superBuffer));
  // });

  // const handleDownload = useCallback(() => {
  //   if (recordedChunks.length) {
  //     const blob = new Blob(recordedChunks, {
  //       type: "video/mp4",
  //     });
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     document.body.appendChild(a);
  //     a.style = "display: none";
  //     a.href = url;
  //     a.download = "react-webcam-stream-capture.mp4";
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     setRecordedChunks([]);
  //   }
  // }, [recordedChunks]);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
  
    if (user) {
      getJudulInterview(user.token, user.id_Event);
      getPembukaanInterview(user.token);
    }
  }, []);

  const getJudulInterview = (token, id_Event) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/pesertaJudul/${id_Event}`,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {
        setJudulHeader(
          JSON.parse(JSON.stringify(response.data)).judul_Interview
        );
      })
      .catch(function (error) {
        // console.log("/peserta/pesertaJudul/idEvent", error);
        console.log("1")
      });
  };

  const getPembukaanInterview = (token) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getPembukaan`,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {

       // setPembukaan(response.data.pembukaan)
        setPembukaan(response.data)


      })
      .catch(function (error) {
        // setCheck(false);

        // openModalContactCenter();
        // console.log("peserta/getPembukaan", error);
        console.log("1")
      });
  };

  // ------------- STEP -----------------
  const [step, setStep] = useState(0);
  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 2 ? 2 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  // ------- Breadcrumb -------
  function toggleActive(index) {
    if (index === step + 1) {
      return {
        color: "red",
        transition: "0.6s",
        padding: "10px 5px",
        fontWeight: "600",
      };
    } else {
      return {
        transition: "0.6s",
        padding: "10px 5px",
        color: "#A0AEC0",
        fontWeight: "600",
      };
    }
  }

  function toggleHidden(index) {
    if (index === step + 1) {
      return false;
    } else {
      return true;
    }
  }

  const dummySoal = [
    {
      id: "1",
      type: "Interviewer",
    },
    {
      id: "2",
      type: "Video",
    },
    {
      id: "3",
      type: "Text",
    },
  ];

  // ------- Text Count -------
  const [count, setCount] = useState(0);

  function matiin(x) {
    if (x < 5) {
      return true;
    } else {
      return false;
    }
  }

  // --------- MODAL -------- //
  const [modalStartTrial, setModalStartTrial] = useState(false);
  const openModalStartTrial = () => {
    setModalStartTrial(!modalStartTrial);
  };

  return (
    <React.Fragment>
      <header style={{ backgroundColor: "#ffffff" }}>
        <h2 style={{ margin: "15px 50px", color: "#E53E3E", fontWeight: "700", fontSize: "28px" }}>
          {judulHeader}
        </h2>

        <header className='headerTrial'>
          <div className='a1'>
            <p style={{ color: "#2D3748", marginBottom: "0px", marginLeft: "50px", fontSize: "14px", fontWeight: "800" }}>
              Subjek Interview
            </p>
            <ol className="breadcrumb m-0">
              {dummySoal.map((item, index) => (
                <li key={index} style={toggleActive(item.id)} value={item.id} className="breadcrumb-item">
                  {item.id}.
                  <span hidden={toggleHidden(item.id)}> {item.type}</span>
                </li>
              ))}
            </ol>
          </div>
          <div className='a2'>
            <p style={{ margin: "0px", fontSize: "500", color: "#718096" }}>
              Anda sedang memulai{" "}
              <span style={{ fontWeight: "700" }}>Trial Interview</span>
            </p>
          </div>
        </header>
      </header>
      <div style={{ margin: "30px" }}>
        {step === 0 && (
          <>
             {pembukaan.map((item) => (
              <> 
              <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033", borderRadius: "8px", width: "680px" }}>
                  <p style={{ fontSize: "24px", fontWeight: "600", color: "#2A4365", margin: "20px 0px" }}>
                    {item.pembukaan}
                  </p>

                <video style={{ width: "480px", borderRadius: "8px",  marginBottom: "40px" }} controls>
                  <source src={item.urlFile} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
                </div>
              </div>
              </>
              ))}
            <div style={{ marginTop: "30px", display: "flex", justifyContent: "center"}}>
              <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }} className="btn btn-success"
                onClick={() => {
                  onNext();
                }}
              >
                Mulai
              </Button>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "25px", gap: "20px" }}>
              <Col xxl={5} md={5}>
                <div style={{ marginTop: "55px", width: "100%", height: "200px", border: "2px dashed #e5e5e5", borderRadius: "12px", padding: "20px", color: "#2A4365" }}>
                  <h5 style={{ color: "#2A4365" }}>
                    Apa kelebihan dan kekurangan anda, coba jelaskan ?
                  </h5>
                </div>
              </Col>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "10px" }}>
                {capturing ? (
                  <div style={{ width: "140px", backgroundColor: "#FFF5F5", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 15px", borderRadius: "10px", cursor: "pointer", border: "1px solid #E53E3E",}}
                    onClick={handleStopCaptureClick}
                  >
                    <img style={{ width: "15px" }} src={stopRekam} />
                    <p style={{ margin: "0", color: "#000000", fontWeight: "700" }}>
                      Stop Rekam
                    </p>
                  </div>
                ) : (
                  <div style={{ width: "140px", backgroundColor: "#F0FFF4", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 15px", borderRadius: "10px", cursor: "pointer", border: "1px solid #48BB78" }}
                    onClick={handleStartCaptureClick}
                  >
                    <img style={{ width: "20px" }} src={mulaiRekam} />
                    <p style={{ margin: "0", color: "#000000", fontWeight: "700" }}>
                      Mulai Rekam
                    </p>
                  </div>
                )}
                <Webcam
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
                  }}
                  width="480"
                  height="360"
                  audioConstraints={{ echoCancellation: false, deviceId: selectedMicId }}
                  videoConstraints={{ deviceId: selectedCameraId }}
                  audio={true}
                  ref={webcamRef}
                  muted={true}
                />
              </div>
            </div>
            <Button style={{ float: "right", padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }} className="btn btn-success"
              onClick={() => {
                onNext();
              }}
              disabled={disablerPreview}
            >
              Lanjut
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
              <Col xxl={5} md={5}>
                <div style={{ marginTop: "55px", width: "100%", height: "200px", border: "2px dashed #e5e5e5", borderRadius: "12px",padding: "20px", color: "#2A4365" }}>
                  <h5>Apa kelebihan dan kekurangan anda coba jelaskan ?</h5>
                </div>
              </Col>
              <Col xxl={6} md={6}>
                <div className="mb-3" style={{marginTop: '55px'}}>
                  <textarea style={{ border: "none", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033", borderRadius: "12px", padding: "40px 30px" }}
                    className="form-control"
                    placeholder="Ketik Jawaban Anda"
                    rows="10"
                    onChange={(e) => setCount(e.target.value.length)}
                    required
                    maxLength={1500}
                  />
                  <span style={{ color: "#505050" }}>* Minimal 5 karakter</span>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                  <p style={{ margin: "0px", fontWeight: "700" }}>
                    <span>Sisa karakter :</span> {1500 - count}
                  </p>
                  <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }} className="btn btn-success"
                    onClick={() => {
                      openModalStartTrial();
                    }}
                    disabled={matiin(count)}
                  >
                    Selesai Trial
                  </Button>
                </div>
              </Col>
            </div>
          </>
        )}
      </div>

      <Modal isOpen={modalStartTrial} toggle={() => { openModalStartTrial() }} centered >
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <img style={{ marginTop: "15px" }} src={FotoModal} />
          <h5 style={{ textAlign: "center", marginTop: "30px" }}>
            <b>Anda Telah Menyelesaikan Trial Interview. </b> <br></br> Lanjut
            Mengerjakan Interview?
          </h5>
          <p style={{ padding: "0 70px", textAlign: "center" }}>
            Sesi Interview akan di mulai saat menekan tombol Mulai, Selamat
            mengerjakan{" "}
          </p>
          <div style={{ display: "flex", justifyContent: "space-between", width: "300px", marginTop: "10px" }} >
            <Button style={{ padding: "0.5rem 2.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px" }} className="btn btn-light"
              onClick={() => {
                setModalStartTrial(false);
              }}
            >
              Kembali
            </Button>
            <Button style={{ width: "120px" }} color="success"
              onClick={() => {
                window.location.href = "/soalInterview";
              }}
            >
              Mulai
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Trial;
