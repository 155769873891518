import React, { useState, useEffect } from "react";

import { Col, Container, Row } from "reactstrap";
import no1 from "../../assets/images/telkom/no1.png";
import no2 from "../../assets/images/telkom/no2.png";
import no3 from "../../assets/images/telkom/no3.png";
import no4 from "../../assets/images/telkom/no4.png";
import no5 from "../../assets/images/telkom/no5.png";
import no6 from "../../assets/images/telkom/no6.png";
import axios from "axios";
import DOMPurify from "dompurify";

const P2 = () => {
  document.title = "Peraturan Interview";
  const [konten, setKonten] = useState("");
  const [judulKonten, setJudulKonten] = useState("");
  const [dataUser, setDataUser] = useState({});

  useEffect((e) => {

    const user = JSON.parse(sessionStorage.getItem("user"));
  
    if (user) {
      setDataUser(user);
      getKonten(user.token)
    }else{
      window.location.href = '/login-perserta';
    }

  }, []);

  const getKonten = (token) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getKonten1?id=` + process.env.REACT_APP_DEFAULT_ID_KONTEN,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {
        setJudulKonten(response.data[0].judul_konten1)
        setKonten(response.data[0].konten1)
      })
      .catch(function (error) {
        // console.log("/peserta/getKonten1", error);
        console.log("1")
      });
  };

  return (
    <React.Fragment>
      <Row style={{ marginTop: '100px', marginLeft: '50px', marginRight: '50px', borderRadius: '10px', boxShadow: '0px 2px 5px #0000001a, 0px 0px 2px #00000033' }}>
        <p style={{ margin: '0', fontSize: '30px', fontWeight: '700', color: '#2A4365', padding: '20px 30px' }}>{judulKonten}</p>
        <hr style={{ color: '#b7b7b7' }} />
        <Col className="quill-only" style={{ marginLeft: '25px', paddingBottom: '25px' }}>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(konten),
            }}
          ></p>

        </Col>
      </Row>

    </React.Fragment>
  );
};

export default P2;
