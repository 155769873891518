import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';
import axios from 'axios'

//import images
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";

//import Components
import SearchOption from '../../Components/Common/SearchOption';
import LanguageDropdown from '../../Components/Common/LanguageDropdown';
import WebAppsDropdown from '../../Components/Common/WebAppsDropdown';
import MyCartDropdown from '../../Components/Common/MyCartDropdown';
import FullScreenDropdown from '../../Components/Common/FullScreenDropdown';
import NotificationDropdown from '../../Components/Common/NotificationDropdown';
import ProfileDropdown from '../../Components/Common/ProfileDropdown';
import LightDark from '../../Components/Common/LightDark';

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
    const [judulHeader , setJudulHeader] = useState('');

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem("user"));
  
        if (user) {
            getJudulInterview(user.token, user.id_Event)
        }
    }, [])
    
    const getJudulInterview = (token, id_Event) => {
        const config = {
          method: 'get',
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/pesertaJudul/${id_Event}`,
          headers: { 
            'x-api-key': token
          }
        };
        
        axios(config)
        .then(function (response) {
          setJudulHeader(JSON.parse(JSON.stringify(response.data)).judul_Interview)
        })
        .catch(function (error) {
            console.log("1")
        //   console.log("/peserta/pesertaJudul/idEvent", error);
        });
       }

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical menu
        if (document.documentElement.getAttribute('data-layout') === "vertical") {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }

        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };
    return (
        <React.Fragment>
            <header style={{backgroundColor: '#ffffff'}} id="page-topbar" className={headerClass}>
                <div className="layout-width" style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}>
                    <div className="navbar-header">
                        <div className="d-flex">
                            <h4 style={{color: '#E53E3E', margin: '0', fontWeight: '700', fontSize: '28px'}}>{judulHeader}</h4>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;