// import React, { useState, useEffect, useRef } from "react";
// import {
//   Col,
//   Container,
//   Row,
//   Input,
//   Label,
//   Button,
//   Modal,
//   ModalBody,
//   ModalFooter,
// } from "reactstrap";
// import axios from "axios";
// // import success from '../../assets/images/telkom/success.svg';

// const SoalLatihan = () => {
//   document.title = "Dashboard | Velzon - React Admin & Dashboard Template";
//   const [inputFields, setInputFields] = useState([]);
//   const [soalInterview, setSoalInterview] = useState([]);
//   const [time, setTime] = useState("");

//   const auth = JSON.parse(localStorage.getItem("user"));
//   const id = auth.id;
//   const idEvent = auth.id_Event;

//   const getSoalById = () => {
//     const config = {
//       method: "get",
//       url: process.env.REACT_APP_BASE_ENDPOINT_BACKEND + `/peserta/${id}/${idEvent}`,
// headers: { 
//     'x-api-key': token
//   }
//     };

//     axios(config)
//       .then(function (response) {
//         const getSoalArray = JSON.parse(
//           JSON.parse(JSON.stringify(response.soal))
//         ).inputFields;
//         setInputFields(getSoalArray);
//         const getSoalTime = JSON.parse(
//           JSON.parse(JSON.stringify(response.soal))
//         ).batasWaktu;
//          console.log("tes  " , getSoalTime)
//         setTime(getSoalTime);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

//   useEffect(() => {
//     getSoalById();
//     clearTimer(getDeadTime());
//     getTime();
//   }, []);

//   const handleChangeWaktu = (event, index) => {
//     const ganti = [...inputFields];
//     ganti[index].waktu = event.target.value;
//     setInputFields(ganti);
//     console.log(ganti)
//   };
//   const handleChangeJawaban = (event, index) => {
//     const ganti = [...inputFields];
//     ganti[index].jawaban = event.target.value;
//     setInputFields(ganti);
//     console.log(ganti)
//   };

//   const submitJawaban = () => {
//     const data = JSON.stringify({
//       id: id,
//       id_Event: idEvent,
//       soal: soalInterview,
//     });

//     const config = {
//       method: "patch",
//       url: process.env. + "/peserta/jawab",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: data,
//     };

//     axios(config)
//       .then(function (response) {
//         console.log(JSON.stringify(response));
//         localStorage.removeItem("user");
//         // window.location.href = '/login-perserta'
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

//   // --------- MODAL Confirm ---------- //
//   const [modalConfirm, setModalConfirm] = useState(false);

//   function openModalConfirm() {
//     // setDetailPertanyaan(JSON.stringify({ batasWaktu: time, inputFields: inputFields }))
//     setSoalInterview(
//       JSON.stringify({ batasWaktu: time, inputFields: inputFields })
//     );
//     setModalConfirm(!modalConfirm);
//   }

//   const [step, setStep] = React.useState(0);

//   const onChange = (nextStep) => {
//     setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
//   };

//   const onNext = () => onChange(step + 1);
//   const onPrevious = () => onChange(step - 1);
//   // ~~~~~~~~ ON NEXT ~~~~~~~~~ //

//   // --------- MODAL ---------- //
//   const [modal_list, setmodal_list] = useState(false);
//   function tog_list() {
//     setmodal_list(!modal_list);
//   }
//   const [modalSuccess, setModalSuccess] = useState(false);
//   // --------- MODAL SUCCESS ---------- //
//   function openModalSuccess() {
//     setModalSuccess(!modalSuccess);
//   }

//   const [days, setDays] = useState(0);
//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);

//   const getTime = () => {
//     const times = Date.parse(time) - Date.now();
//     console.log("ckckc  ", Date.parse(time));
//     console.log("haha  ", Date.now());

//     setDays(Math.floor(times / (1000 * 60 * 60 * 24)));
//     setHours(Math.floor((times / (1000 * 60 * 60)) % 24));
//     setMinutes(Math.floor((times / 1000 / 60) % 60));
//     setSeconds(Math.floor((times / 1000) % 60));
//   };

//   const Ref = useRef(null);
//   const [timer, setTimer] = useState("00:00:00");

//   const getTimeRemaining = (e) => {
//     const total = Date.parse(e) - Date.parse(new Date());
//     const seconds = Math.floor((total / 1000) % 60);
//     const minutes = Math.floor((total / 1000 / 60) % 60);
//     const hours = Math.floor((total / 1000 / 60 / 60) % 24);
//     return {
//       total,
//       hours,
//       minutes,
//       seconds,
//     };
//   };

//   const startTimer = (e) => {
//     let { total, hours, minutes, seconds } = getTimeRemaining(e);
//     if (total >= 0) {
//       setTimer(
//         (hours > 9 ? hours : "0" + hours) +
//           ":" +
//           (minutes > 9 ? minutes : "0" + minutes) +
//           ":" +
//           (seconds > 9 ? seconds : "0" + seconds)
//       );
//     }
//   };

//   const clearTimer = (e) => {
//     setTimer(time);

//     if (Ref.current) clearInterval(Ref.current);
//     const id = setInterval(() => {
//       startTimer(e);
//     }, 1000);
//     Ref.current = id;
//   };

//   const getDeadTime = () => {
//     let deadline = new Date();
//     var a = time.split(":"); // split it at the colons
//     var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
//     console.log("dead   ", seconds);
//     deadline.setSeconds(deadline.getSeconds() + seconds);
//     return deadline;
//   };

//   const onClickReset = () => {
//     clearTimer(getDeadTime());
//   };

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         {inputFields.map((data, index) => (
//           <>
//             {step === index && (
//               <>
//                 <div
//                   className="align-middle header"
//                   style={{
//                     display: "flex",
//                     color: "black",
//                     backgroundColor: "#F7FAFC",
//                     padding: "20px",
//                     marginBottom: "50px",
//                     justifyContent: "space-between",
//                   }}
//                 >
//                   <span>
//                     Subjek Interview {index + 1}/{inputFields.length}
//                   </span>
//                   <span style={{ backgroundColor: "#C6F6D5" }}>
//                     Sisa Waktu {timer}
//                   </span>
//                 </div>

//                 {data.type === "interviewer" ? (
//                   <div
//                     className="d-flex align-items-center flex-column"
//                     style={{
//                       marginTop: "20px",
//                       width: "100%",
//                       textAlign: "center",
//                     }}
//                   >
//                     <div
//                       className="p-2"
//                       style={{
//                         margin: "20px",
//                         width: "50%",
//                         borderRadius: "10px",
//                         border: "solid #DCDCDC",
//                       }}
//                     >
//                       <h3
//                         style={{
//                           padding: "20px",
//                           width: "100%",
//                           marginBottom: "50px",
//                           textAlign: "center",
//                           borderBottom: "solid #DCDCDC",
//                         }}
//                       >
//                         {data.pertanyaan}
//                       </h3>
//                       <div style={{ margin: "30px", textAlign: "center" }}>
//                         <video width="75%" controls>
//                           <source
//                             src="https://www.youtube.com/watch?v=PbpJgAFSsmA"
//                             // type={videoSrc.type}
//                           />
//                           Your browser does not support HTML5 video.
//                         </video>
//                       </div>
//                     </div>
//                     <div className="p-2">
//                       <Button
//                         style={{ width: "120px", float: "right" }}
//                         color="success"
//                         onClick={onNext}
//                       >
//                         Lanjut
//                       </Button>
//                     </div>
//                   </div>
//                 ) : (
//                   <div>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <Col xxl={5} md={5}>
//                         <div
//                           style={{
//                             marginTop: "20px",
//                             width: "100%",
//                             height: "300px",
//                             border: "3px dashed #e5e5e5",
//                             padding: "20px",
//                           }}
//                         >
//                           <div className="mb-3">
//                             <h5>{data.pertanyaan}</h5>
//                           </div>
//                         </div>
//                       </Col>
//                       <Col xxl={5} md={5}>
//                         {data.type === "video" && (
//                           <div className="mb-3">
//                             <video
//                               width="100%"
//                               controls
//                               onChange={(event) =>
//                                 handleChangeWaktu(event, index)
//                               }
//                             >
//                               <source
//                               // src={videoSrc.Src}
//                               // type={videoSrc.type}
//                               />
//                               Your browser does not support HTML5 video.
//                             </video>
//                             <Label className="form-label ">
//                               Batas Waktu Menjawab ({data.waktu} Menit)
//                             </Label>
//                           </div>
//                         )}

//                         {data.type === "text" && (
//                           <div className="mb-3">
//                             <textarea
//                               style={{
//                                 border: "none",
//                                 boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)",
//                                 borderRadius: "12px",
//                                 padding: "40px 30px",
//                               }}
//                               className="form-control"
//                               placeholder="Ketik Jawaban Anda di sini ..."
//                               rows="10"
//                               required
//                               value={data.jawaban}
//                               onChange={(event) =>
//                                 handleChangeJawaban(event, index)
//                               }
//                             />
//                             <Label className="form-label">
//                               Batas Waktu Menjawab ({data.waktu} Menit)
//                             </Label>
//                           </div>
//                         )}
//                       </Col>
//                     </div>

//                     {index === inputFields.length - 1 ? (
//                       <div
//                         style={{
//                           margin: "50xp",
//                           display: "flex",
//                           justifyContent: "end",
//                         }}
//                       >
//                         <Button
//                           style={{ width: "120px", float: "right" }}
//                           color="success"
//                           onClick={() => openModalConfirm()}
//                         >
//                           Selesai Interview
//                         </Button>
//                       </div>
//                     ) : (
//                       <div
//                         style={{
//                           margin: "50xp",
//                           display: "flex",
//                           justifyContent: "end",
//                         }}
//                       >
//                         <Button
//                           style={{ width: "120px", float: "right" }}
//                           color="success"
//                           onClick={onNext}
//                         >
//                           Lanjut
//                         </Button>
//                       </div>
//                     )}
//                   </div>
//                 )}
//               </>
//             )}
//             {/* <Row>
//               <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
//                 <div>
//                   <Label className="form-label">Pertanyaan {1 + index}</Label>
//                   <Input disabled value={data.pertanyaan}  type='text' className="form-control" />
//                 </div>
//               </Col>
//               <Col style={{display: 'none'}} xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
//                 <Label className="form-label">Type</Label>
//                   <select value={data.type}  className="form-select mb-3" aria-label="Default select example">
//                     <option value="" selected="selected" hidden="hidden">Choose here</option>
//                     <option value="text">Text</option>
//                     <option value="video">Video</option>
//                     <option value="interviewer">Interviewer</option>
//                   </select>
//               </Col>
//               <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
//                 <Label className="form-label">Jawab</Label>
//                 <Input value={data.jawaban} onChange={event => handleChangeJawaban(event, index)} type='text' className="form-control" />
//               </Col>
//               {data.type === 'video' && (
//                 <Col style={{display: 'none'}} xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
//                   <div>
//                     <Label className="form-label">Batas Waktu Menjawab (Menit)</Label>
//                     <Input value={data.waktu} onChange={event => handleChangeWaktu(event, index)} type='number' className="form-control" />
//                   </div>
//                 </Col>
//               )}
              
//             </Row> */}
//           </>
//         ))}
//         {/* <Button onClick={() => openModalConfirm()}>Dor</Button> */}
//       </div>

//       {/* ------- MODAL CONFIRM ------- */}
//       <Modal
//         isOpen={modalConfirm}
//         toggle={() => {
//           openModalConfirm();
//         }}
//         centered
//       >
//         <ModalBody
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <h5 style={{ textAlign: "center", marginTop: "30px" }}>Suksess </h5>
//           {/* <p style={{ padding: '0 70px', textAlign: 'center' }}>Apakah anda yakin untuk menghapus data ini?</p> */}
//         </ModalBody>
//         <ModalFooter style={{ justifyContent: "center" }}>
//           {/* <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-light" onClick={() => { openModalConfirm() }}>Batal</Button> */}
//           <Button
//             style={{ padding: "0.5rem 3.0rem" }}
//             className="btn btn-success"
//             onClick={() => {
//               submitJawaban();
//               openModalConfirm();
//             }}
//           >
//             Ok
//           </Button>
//         </ModalFooter>
//       </Modal>

//       {/* ---------- MODAL SUCCESS TAMBAH ----------
//        <Modal size="md" isOpen={modalSuccess} toggle={() => {openModalSuccess()}} centered>
//         <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '90px 10px'}}>
//           <img src={success} />
//           <h5 style={{textAlign: 'center', margin: '20px'}}>Data Assessor Berhasil Ditambahkan</h5>
//         </div>
//       </Modal> */}
//     </React.Fragment>
//   );
// };

// export default SoalLatihan;
