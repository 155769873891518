import React, { useState, useRef, useEffect } from 'react';
import { Alert, Card, Col, Container, Input, Label, Row, Button, Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
import Webcam from "react-webcam";
// import ReCAPTCHA from 'react-google-recaptcha'

import logoTelkom from "../../../assets/images/telkom/logoACI.png";
import Welcome from "../../../assets/images/telkom/welcomeNew.png";

const CoverSignIn = () => {
    const webcamRef = useRef(null);

    document.title = "Login";

    const [kode, setKode] = useState('');
    const [errorKode, setErrorKode] = useState('');
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorSubmit, setErrorSubmit] = useState(true);
    const [errorSubmitKonten, setErrorSubmitKonten] = useState(true);
    const [kontenError, setKontenError] = useState('');
    const [inputFields, setInputFields] = useState([]);
    const [idPeserta, setIdPeserta] = useState(null);
    const [sambutan, setSambutan] = useState(true);

    const [disablerChoose, setDisablerChoose] = useState(false);
    const [cameras, setCameras] = useState([]);
    const [mics, setMics] = useState([]);
    const [selectedCameraId, setSelectedCameraId] = useState("");
    const [selectedMicId, setSelectedMicId] = useState("");

    const [bersuara, setBersuara] = useState(true);

    // ReCAPTCHA
    // const captchaRef = useRef(null)

    // --------- MODAL ----------- //
    // const [modalWarning, setModalWarning] = useState(false);
    // const [modalReCAPTCHA, setModalReCAPTCHA] = useState(false);

    const changeKode = (event) => {
        const value = event.target.value
        setKode(value)
        if (!value) {
            setErrorKode('Maaf, kode tidak boleh kosong!')
        } else {
            setErrorKode('')
        }
    }
    const changeEmail = (event) => {
        const value = event.target.value
        setEmail(value)
        if (!value) {
            setErrorEmail('Maaf, Email tidak boleh kosong!')
        } else {
            setErrorEmail('')
        }
    }

    const [modalLimitLogin, setModalLimitLogin] = useState(false)

    const loginKode = () => {
        setErrorSubmitKonten(true)

        // if (captchaRef.current.getValue() !== "") {
        const data = JSON.stringify({
            "email": email,
            "kode": kode
        });

        const config = {
            method: 'patch',
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/login`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                const status = response.status

                if (status === 310) {
                    setKontenError("Interview Telah Berakhir")
                    setErrorSubmitKonten(false)
                    setTimeout(() => {
                        setErrorSubmitKonten(true)
                    }, 3000)
                }
                if (status === 311) {
                    setKontenError("Interview Belum Di Mulai")
                    setErrorSubmitKonten(false)
                    setTimeout(() => {
                        setErrorSubmitKonten(true)
                    }, 3000)
                }
                if (status === 401) {
                    setKontenError("Kode sudah digunakan")
                    setErrorSubmitKonten(false)
                    setTimeout(() => {
                        setErrorSubmitKonten(true)
                    }, 3000)
                }
                if (status === 200) {
                    // nextnya return soal aku hapus
                    // localStorage.setItem('user', JSON.stringify(response.data))

                    sessionStorage.setItem('user', JSON.stringify(response.data))
                    window.location.href = '/p2'
                    // const input = JSON.parse(response.soal)
                    // setIdPeserta(response.data.id)

                    // const soalnya = [...input]
                    // const typePengantar = soalnya.map((item) => item.type === "pengantar" ? true : false);
                    // const typeVideo = soalnya.map((item) => item.type === "video&video" ? true : false);

                    // const namaBuket = process.env.REACT_APP_BUCKET_NAME
                    // if (typePengantar.indexOf(true) !== -1) {
                    //     const data = JSON.stringify({
                    //         "bucketName": namaBuket,
                    //         "fileName": soalnya[typePengantar.indexOf(true)].namaVideoSoal,
                    //     });

                    //     const config = {
                    //         method: 'patch',
                    //         url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getFile`,
                    //         headers: {
                    //             'Content-Type': 'application/json'
                    //         },
                    //         data: data
                    //     };

                    //     axios(config)
                    //         .then(function (response) {
                    //             soalnya[typePengantar.indexOf(true)].file = response.data
                    //         })
                    //         .catch(function (error) {
                    //             console.log(error);
                    //         });

                    //     if (typeVideo.indexOf(true) !== -1) {

                    //         let filteredArray = soalnya.filter((item, index) => item.type === "video&video");
                    //         let indexArray = filteredArray.map(item => soalnya.indexOf(item));

                    //         for (let index = 0; index < indexArray.length; index++) {

                    //             const data = JSON.stringify({
                    //                 "bucketName": namaBuket,
                    //                 "fileName": soalnya[indexArray[index]].namaVideoSoal,
                    //             });

                    //             const config = {
                    //                 method: 'patch',
                    //                 url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getFile`,
                    //                 headers: {
                    //                     'Content-Type': 'application/json'
                    //                 },
                    //                 data: data
                    //             };

                    //             axios(config)
                    //                 .then(function (response) {
                    //                     soalnya[indexArray[index]].tanya = response.data
                    //                     setModalWarning(true)
                    //                 })
                    //                 .catch(function (error) {
                    //                     console.log(error);
                    //                 });
                    //         }

                    //     } else {
                    //         setModalWarning(true)
                    //     }

                    // } else {

                    //     if (typeVideo.indexOf(true) !== -1) {

                    //         let filteredArray = soalnya.filter((item, index) => item.type === "video&video");
                    //         let indexArray = filteredArray.map(item => soalnya.indexOf(item));

                    //         for (let index = 0; index < indexArray.length; index++) {

                    //             const data = JSON.stringify({
                    //                 "bucketName": namaBuket,
                    //                 "fileName": soalnya[indexArray[index]].namaVideoSoal,
                    //             });

                    //             const config = {
                    //                 method: 'patch',
                    //                 url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getFile`,
                    //                 headers: {
                    //                     'Content-Type': 'application/json'
                    //                 },
                    //                 data: data
                    //             };

                    //             axios(config)
                    //                 .then(function (response) {
                    //                     soalnya[indexArray[index]].tanya = response.data
                    //                     setModalWarning(true)
                    //                 })
                    //                 .catch(function (error) {
                    //                     console.log(error);
                    //                     setModalWarning(true)
                    //                 });
                    //         }

                    //     } else {
                    //         setModalWarning(true)
                    //     }

                    // }

                    // setInputFields(soalnya)


                }
                console.log(response)

            })
            .catch(function (error) {
                console.log("/peserta/login", error)
                if (error == "Request failed with status code 429") {
                    setModalLimitLogin(true)
                } else {
                    setErrorSubmit(false);
                    setTimeout(() => {
                        setErrorSubmit(true)
                    }, 5000)
                }
            });
        // } else {
        //     setModalReCAPTCHA(true)
        // }

    }

    // const warning = () => {
    //     const auth = JSON.parse(sessionStorage.getItem("user"));
    //     const data = JSON.stringify({
    //         "id": idPeserta,
    //         "soal": JSON.stringify(inputFields),
    //     });

    //     const config = {
    //         method: 'patch',
    //         url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/updateSoal`,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'x-api-key': auth.token
    //         },
    //         data: data
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             window.location.href = '/p2'
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });

    // }

    useEffect(() => {

        if (!navigator.mediaDevices?.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
        } else {
            navigator.mediaDevices.enumerateDevices().then(function (devices) {
                const cameraList = devices.filter((device) => device.kind === "videoinput");
                setCameras(cameraList);
            })
                .catch(function (err) {
                    console.log("Error Video Input", err);
                });

            navigator.mediaDevices.enumerateDevices().then(function (devices) {
                const audioList = devices.filter((device) => device.kind === "audioinput")
                setMics(audioList)
            })
                .catch(function (err) {
                    console.log("Error Audio Input", err);
                });
        }
        //   const hai = navigator.mediaDevices.getSupportedConstraints().echoCancellation;
    }, [])

    useEffect(() => {
        console.log("ANTI RELOAD")
        window.addEventListener("beforeunload", (event) => {
        // Cegah reload default
        event.preventDefault();

        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;

        });
    
        // Bersihkan listener saat komponen di-unmount
        return () => {
        window.removeEventListener("beforeunload", (event) => {
            // Cegah reload default
            event.preventDefault();

            // Included for legacy support, e.g. Chrome/Edge < 119
            event.returnValue = true;

            });
        };
    },[])


    const handleChangeAudio = (event) => {
        setSelectedMicId(event.target.value);
        sessionStorage.setItem("Mic", event.target.value)
    };

    const handleChangeVideo = (event) => {
        setSelectedCameraId(event.target.value);
        sessionStorage.setItem("Camera", event.target.value)
    };

    return (
        <React.Fragment>

            <Row style={{ margin: '0px', height: '100vh', alignItems: 'center', fontFamily: "Nunito" }}>
                <Col md={12} lg={6} xl={6} style={{ height: '100%', padding: '0px' }}>
                    <img src={Welcome} style={{ height: '-webkit-fill-available' }} />
                </Col>

                <Col md={12} lg={6} xl={6} style={{ padding: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <div style={{ display: 'flex', justifyContent: 'center', marginRight: '50px' }}>
                        <img src={logoTelkom} />
                    </div>

                    <div style={{ marginTop: '50px', padding: '25px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)', borderRadius: '8px', width: '65%', marginRight: '50px' }}>
                        <Alert hidden={errorSubmit} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s' }}>
                            Something wrong
                        </Alert>
                        <Alert hidden={errorSubmitKonten} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s' }}>
                            {kontenError}
                        </Alert>

                        <div className="mb-3">
                            <Label htmlFor="kodeInterview" className="form-label">Email</Label>
                            <Input type="text" value={email} onChange={changeEmail} className="form-control" id="kodeInterview" placeholder="Masukkan Email" maxLength={60} required />
                            {errorEmail && (
                                <p style={{ margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute' }}>{errorEmail}</p>
                            )}
                        </div>

                        <div className="mb-3">
                            <Label htmlFor="kodeInterview" className="form-label">Kode Interview</Label>
                            <Input type="text" value={kode} onChange={changeKode} className="form-control" id="kodeInterview" placeholder="Masukkan Kode Interview" maxLength={25} required />
                            {errorKode && (
                                <p style={{ margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute' }}>{errorKode}</p>
                            )}
                        </div>

                        {/* <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} /> */}

                        <div className="mt-4">
                            <Button color="success" className="w-100" onClick={() => { loginKode() }} >Masuk</Button>
                        </div>
                    </div>
                </Col>
            </Row>

            {/* ------- MODAL WARNING ------- */}
            {/* <Modal isOpen={modalWarning} toggle={() => { }} centered>
                <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto", fontFamily: 'Nunito' }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h5 style={{ textAlign: "center", padding: "0 70px", margin: '50px 0px' }}>
                            Silahkan Masuk
                        </h5>
                        <Button color='success' onClick={() => { warning() }}>Masuk</Button>
                    </div>
                </ModalBody>
            </Modal> */}

            <Modal isOpen={sambutan} toggle={() => { }} size="lg" centered>
                <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", fontFamily: 'Nunito' }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h5 style={{ textAlign: "center", padding: "0 70px", margin: '30px 0px', fontSize: '24px' }}>
                            Selamat Datang di Web Interview Telkom ACI
                        </h5>
                        <p style={{ fontSize: '16px', textAlign: 'justify', padding: "0px 70px" }}>Pastikan kembali perangkat anda, karena di Web Interview Telkom ACI ada Proses pengambilan video dan audio. Pastikan memberi akses izin Video dan Audio untuk merekam, jika belum terlihat video di layar anda silahkan Refresh Page ini</p>
                        <Webcam
                            style={{
                                borderRadius: "10px",
                                boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
                            }}
                            width="480"
                            height="360"
                            audioConstraints={{ echoCancellation: false, deviceId: selectedMicId }}
                            videoConstraints={{ deviceId: selectedCameraId }}
                            audio={true}
                            ref={webcamRef}
                            muted={bersuara}
                        />
                        <Button style={{ fontSize: '16px', marginTop: '20px' }} color={bersuara == true ? "warning" : "success"} onClick={() => { setBersuara(!bersuara) }}>
                            {bersuara == true ? "Sound OFF" : "Sound ON"}
                        </Button>
                        <label style={{ fontSize: '14px' }}>Pilih Webcam:</label>
                        <select
                            style={{ fontSize: '14px' }}
                            value={selectedCameraId}
                            onChange={(event) => {
                                handleChangeVideo(event);
                            }}
                            disabled={disablerChoose}
                        >
                            {cameras.map((camera) => (
                                <option key={camera.deviceId} value={camera.deviceId}>
                                    {camera.label}
                                </option>
                            ))}
                        </select>
                        <label style={{ fontSize: '14px' }}>Pilih Microphone:</label>
                        <select
                            style={{ fontSize: '14px' }}
                            value={selectedMicId}
                            onChange={(event) => {
                                handleChangeAudio(event);
                            }}
                            disabled={disablerChoose}
                        >
                            {mics.map((device) => (
                                <option key={device.deviceId} value={device.deviceId}>
                                    {device.label}
                                </option>
                            ))}
                        </select>
                        <Button style={{ fontSize: '16px', marginTop: '20px' }} color='success' onClick={() => { setSambutan(false) }}>Sudah terlihat jelas</Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalLimitLogin} toggle={() => { }} centered>
                <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h5 style={{ textAlign: "center", padding: "0 50px", margin: '50px 0px' }}>
                            Terlalu banyak melakukan percobaan,<br />Mohon tunggu beberapa menit
                        </h5>
                        <Button color="success" onClick={() => { setModalLimitLogin(false) }}>Oke Deh</Button>
                    </div>
                </ModalBody>
            </Modal>

            {/* ------- MODAL CAPTCHA ------- */}
            {/* <Modal isOpen={modalReCAPTCHA} toggle={() => { }} centered>
                <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h5 style={{ textAlign: "center", padding: "0 70px", margin: '50px 0px' }}>
                            reCAPTCHA dulu kawan
                        </h5>
                        <Button color="success" onClick={() => { setModalReCAPTCHA(false) }}>Oke Deh</Button>
                    </div>
                </ModalBody>
            </Modal> */}

        </React.Fragment>
    );
};

export default CoverSignIn;