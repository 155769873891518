import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import {
  Col,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import FotoModal from "../../assets/images/telkom/modal.png";

import axios from "axios";
import DOMPurify from "dompurify";
const P3 = () => {
  document.title = "Petunjuk Pengoperasian";

  const [konten, setKonten] = useState("");
  const [judulkonten, setJudulKonten] = useState("");
  const [dataUser, setDataUser] = useState({});


  useEffect((e) => {

    const user = JSON.parse(sessionStorage.getItem("user"));
  
    if (user) {
      setDataUser(user);
      getKonten(user.token)
    }else{
      window.location.href = '/login-perserta';
    }

  }, []);
  
  const getKonten = (token) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getKonten2?id=` + process.env.REACT_APP_DEFAULT_ID_KONTEN,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {
        setJudulKonten(response.data[0].judul_konten2)
        setKonten(response.data[0].konten2)
      })
      .catch(function (error) {
        // setCheck(false);

        // openModalContactCenter();
        // console.log("/peserta/getKonten2", error);
        console.log("1")
      });
  };
  if (sessionStorage.getItem('user') == null) {
    window.location.href = '/login-perserta';
  }

  return (
    <React.Fragment>
      <Row
        style={{
          marginTop: "100px",
          marginLeft: "50px",
          marginRight: "50px",
          borderRadius: "10px",
          boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
        }}
      >
        <p
          style={{
            margin: "0",
            fontSize: "30px",
            fontWeight: "700",
            color: "#2A4365",
            padding: "20px 30px",
          }}
        >

          {judulkonten}
        </p>
        <hr style={{ color: "#b7b7b7" }} />
        <Col className="quill-only" style={{ marginLeft: '25px', paddingBottom: '25px' }}>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(konten),
            }}
          ></p>
          {/* <ol
            style={{
              fontSize: "18px",
              fontWeight: "700",
              padding: "25px 0px 25px 40px",
            }}
          >
            <li>
              <p style={{ fontWeight: "500", margin: "0" }}>
              Sebelum melakukan proses interview, Anda diberikan kesempatan untuk melakukan trial terlebih dahulu untuk memastikan kehandalan perangkat yang digunakan.
              </p>
            </li>
            <li>
              <p style={{ fontWeight: "500", margin: "0" }}>
              Dalam proses trial  Anda dapat mencoba merekam jawaban baik dalam bentuk teks maupun video.
              </p>
            </li>
            <li>
              <p style={{ fontWeight: "500", margin: "0", textAlign:"justify" }}>
              Terdapat batasan karakter untuk jawaban dalam bentuk teks dan batasan waktu untuk jawaban dalam bentuk video, serta batasan waktu total untuk pelaksanaan proses interview. Batas waktu total secara keseluruhan dapat dilihat pada sudut kanan atas sedangkan batas waktu untuk merekam jawaban terletak pada bagian bawah kotak rekam.
              </p>
            </li>
            <li>
              <p style={{ fontWeight: "500", margin: "0" }}>
              Untuk jawaban dalam bentuk video, klik tombol "Preview" untuk melihat hasil rekaman sesi trial interview Anda.
              </p>
            </li>
            <li>
              <p style={{ fontWeight: "500", margin: "0" }}>
              Setelah menyelesaikan sesi trial, silakan memulai sesi interview Anda dengan menekan tombol "Mulai".
              </p>
            </li>
            <li>
              <p style={{ fontWeight: "500", margin: "0" }}>
              Ketika video pembukaan interview tampil, Anda bisa menekan tombol "Selanjutnya" untuk melihat soal.
              </p>
            </li>
            <li>
              <p style={{ fontWeight: "500", margin: "0", textAlign:"justify"  }}>
              Baca atau dengarkan pertanyaan yang diajukan secara seksama dan jawablah baik dalam bentuk teks ataupun video sesuai dengan arahan yang diberikan. Jika sudah selesai menjawab silakan tekan tombol "Submit". Anda hanya diberikan kesempatan 1 kali untuk menjawab pertanyaan, tidak ada kesempatan untuk mengulang jawaban.
              </p>
            </li>
            <li>
              <p style={{ fontWeight: "500", margin: "0", textAlign:"justify"  }}>
              Jika batas waktu telah habis dan Anda belum menekan tombol submit, maka jawaban tidak terekam ke dalam sistem. Mohon untuk dapat merekam jawaban sesuai dengan batas waktu yang ditetapkan.
              </p>
            </li>
          </ol> */}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default P3;
