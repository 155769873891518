import React, { useEffect, useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";

import {
  Col,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import FotoModal from "../../assets/images/telkom/modal.png";
import mulaiRekam from "../../assets/images/telkom/mulaiRekam.svg";
import stopRekam from "../../assets/images/telkom/stopRekam.svg";
// import ReactPlayer from 'react-player'
// import VideoRecorder from 'react-video-recorder'
// import { useReactMediaRecorder } from "react-media-recorder";
import axios from "axios";

const P4 = () => {
  document.title = "Trial Device";

  // const [screenshot, setScreenShot] = useState(null);
  // const screenRef = useRef(null);

  // const handleScreenshot = async () => {
  //   const canvas = await html2canvas(screenRef.current, {
  //     useCORS: true
  //   });

  //   setScreenshot(canvas.toDataURL());
  // };

  // if (localStorage.getItem('user') == null) {
  //   window.location.href = '/login-peserta';
  // }
  if (sessionStorage.getItem('user') == null) {
    window.location.href = '/login-perserta';
  }

  // -------- WEBCAM -------- //
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [uploadMotionFile, setUploadMotionFile] = useState([]);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [disablerPreview, setDisablerPreview] = useState(true);
  const [disablerChoose, setDisablerChoose] = useState(false);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setDisablerChoose(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  //  const handleDataAvailable = React.useCallback(
  //    ({ data }) => {
  //      if (data.size > 0) {
  //        setRecordedChunks((prev) => prev.concat(data));
  //        setRecordedBlob(data);
  //        const blob = new Blob(recordedChunks, {
  //          type: "video/webm"
  //        });
  //        const url =URL.createObjectURL(blob);
  //        const a = window.URL.createObjectURL(blob);

  //        const uri = a.split("blob:") ;
  //        setVideoUrl (a);
  //      }
  //    },
  //    [setRecordedChunks]
  //  );

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setDisablerPreview(false);
    setDisablerChoose(false);
  }, [mediaRecorderRef, setCapturing]);

  const handlePlay = useCallback(() => {

    if (recordedChunks.length) {
      var superBuffer = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      setFileInfo(window.URL.createObjectURL(superBuffer));
    }
  });

  const handleDownload = useCallback(() => {

    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      const url = URL.createObjectURL(blob);
      //setVideoUrlBlob (url);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.mp4";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);
  // ~~~~~~ WEBCAM ~~~~~~ //

  //  const handlePlay = React.useCallback(() => {
  //    // if (recordedChunks.length) {
  //      const blob = new Blob(recordedChunks, {
  //        type: "video/webm"
  //      });

  //      mediaRecorderRef.current.src = null;
  //      mediaRecorderRef.current.srcObject = null;

  //       mediaRecorderRef.current.src = window.URL.createObjectURL(blob);

  //     // mediaRecorderRef.current.play();
  //     // setRecordedChunks([]);
  //      // const url = URL.createObjectURL(blob);
  //      // const a = document.createElement("a");
  //      // document.body.appendChild(a);
  //      // a.style = "display: none";
  //      // a.href = url;
  //      // a.download = "react-webcam-stream-capture.webm";
  //      // a.click();
  //      // window.URL.revokeObjectURL(url);
  //      // setRecordedChunks([]);
  //    }
  //  , [mediaRecorderRef]);

  // ---------- ON NEXT --------- //
  const [step, setStep] = React.useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  // ~~~~~~~~ ON NEXT ~~~~~~~~~ //

  // --------- MODAL ---------- //
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  const [modalStartTrial, setModalStartTrial] = useState(false);
  const openModalStartTrial = () => {
    setModalStartTrial(!modalStartTrial);
  };

  const [cameras, setCameras] = useState([]);
  const [mics, setMics] = useState([]);
  const [selectedCameraId, setSelectedCameraId] = useState("");
  const [selectedMicId, setSelectedMicId] = useState("");

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices()
      .then(function (devices) {
        const cameraList = devices.filter((device) => device.kind === "videoinput");
        setCameras(cameraList);
      })
      .catch(function (err) {
        console.log("Error Video Input", err);
      });

    navigator.mediaDevices.enumerateDevices()
      .then(function (devices) {
        const audioList = devices.filter((device) => device.kind === "audioinput")
        setMics(audioList)
      })
      .catch(function (err) {
        console.log("Error Audio Input", err);
      });
    const hai = navigator.mediaDevices.getSupportedConstraints().echoCancellation;
  }, []);

  const handleChangeVideo = (event) => {
    setSelectedCameraId(event.target.value);
    sessionStorage.setItem("Camera", event.target.value)
  };

  const handleChangeAudio = (event) => {
    setSelectedMicId(event.target.value);
    sessionStorage.setItem("Mic", event.target.value)
  };

  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <React.Fragment>
      <div className="page-content">
        {step === 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "25px",
                gap: "20px",
              }}
            >
              <Col xxl={4} md={4}>
                <div
                  style={{
                    marginTop: "55px",
                    width: "100%",
                    height: "200px",
                    border: "2px dashed #e5e5e5",
                    borderRadius: "12px",
                    padding: "20px",
                    color: "#2A4365",
                  }}
                >
                  <h5 style={{ color: "#2A4365" }}>
                    Silakan pastikan perangkat anda (audio & video) dalam
                    kondisi baik
                  </h5>
                </div>
              </Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "10px",
                }}
              >
                {capturing ? (
                  <div
                    style={{
                      width: "140px",
                      backgroundColor: "#FFF5F5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      border: "1px solid #E53E3E",
                    }}
                    onClick={handleStopCaptureClick}
                  >
                    <img style={{ width: "15px" }} src={stopRekam} />
                    <p
                      style={{
                        margin: "0",
                        color: "#000000",
                        fontWeight: "700",
                      }}
                    >
                      Stop Rekam
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "140px",
                      backgroundColor: "#F0FFF4",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      border: "1px solid #48BB78",
                    }}
                    onClick={handleStartCaptureClick}
                  >
                    <img style={{ width: "20px" }} src={mulaiRekam} />
                    <p
                      style={{
                        margin: "0",
                        color: "#000000",
                        fontWeight: "700",
                      }}
                    >
                      Mulai Rekam
                    </p>
                  </div>
                )}
                <Webcam
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
                  }}
                  width="480"
                  height="360"
                  audioConstraints={{ echoCancellation: false, deviceId: selectedMicId }}
                  videoConstraints={{ deviceId: selectedCameraId }}
                  audio={true}
                  ref={webcamRef}
                  muted={true}
                />

                {/* {recordedChunks.length > 0 && (
                <>
                <button onClick={handleDownload}>Download</button>
                <button onClick={handlePlay}>Play</button>
                </>
              )} */}

                <label>Pilih Webcam:</label>
                <select
                  value={selectedCameraId}
                  onChange={(event) => {
                    handleChangeVideo(event);
                  }}
                  disabled={disablerChoose}
                >
                  {cameras.map((camera) => (
                    <option key={camera.deviceId} value={camera.deviceId}>
                      {camera.label}
                    </option>
                  ))}
                </select>


                <label>Pilih Microphone:</label>
                <select
                  value={selectedMicId}
                  onChange={(event) => {
                    handleChangeAudio(event);
                  }}
                  disabled={disablerChoose}
                >
                  {mics.map((device) => (
                    <option key={device.deviceId} value={device.deviceId}>
                      {device.label}
                    </option>
                  ))}
                </select>


                {/* {devices.map((device, key) => (
                <div>
                  <Webcam audio={false} videoConstraints={{ deviceId: device.deviceId }} />
                  {device.label || `Device ${key + 1}`}
                </div>
              ))} */}

                {/* <video ref={gumVideo} id="gum" autoplay muted playsinline></video>
              <video ref={recordedVideo} id="recorded" autoplay loop playsinline></video>

              <div>
                {mulai ? (
                <>
                
                </>
                ) : (
                <>
                  
                </>
                )}
                <button id="record">Start Recording</button>
                <button id="play" disabled>Play</button>
                <button id="download" disabled>Download</button>
              </div> */}

                {/* {capturing ? (
                 <>
                   <Webcam width="480" height="360" audio={true} ref={webcamRef} />
                  
                   <button onClick={handleStopCaptureClick}>Stop Capture</button>
                 </>
               ) : (
                 <>
                   <Webcam width="480" height="360" audio={true} ref={webcamRef} />
                   <ReactPlayer url={videoUrl} />
                   <video src ={videoUrl} playsInline autoPlay muted></video>
                  //  <video  playsInline loop></video>
                  //  {recordedChunks}
                  //  {videoBlob!=null && (
                  //  <ReactPlayer url={videoBlob} />
                  //  )}
                   <button onClick={handleStartCaptureClick}>Start Capture</button>
                 </>
               )}
               {recordedChunks.length > 0 && (
                   <div> <button onClick={handlePlay}>play</button>
                   <button onClick={handleDownload}>Download</button></div>
               )} */}
              </div>
            </div>
            <Button
              style={{
                padding: "0.5rem 3.0rem",
                fontWeight: "700",
                borderRadius: "6px",
                float: "right",
              }}
              className="btn btn-success"
              onClick={() => {
                onNext();
                handlePlay();
              }}
              disabled={disablerPreview}
            >
              Pratinjau
            </Button>

            {/* <div ref={screenRef}>
              
              <p>Hai :D</p>
              <button onClick={handleScreenshot}>Take Screenshot</button>
              {screenshot && <img src={screenshot} />}
            </div> */}
          </>
        )}

        {step === 1 && (
          <>
            <div
              style={{
                padding: "30px 1px",
                margin: "0px 20px",
                display: "flex",
                justifyContent: "center",
                border: "1px solid #e5e5e5",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            >
              <video
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
                  width: "480px",
                  height: "360px",
                }}
                src={fileInfo}
                playsInline
                controls
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px 20px",
              }}
            >
              <Button
                style={{
                  padding: "0.5rem 3.0rem",
                  backgroundColor: "#ffffff",
                  color: "#718096",
                  border: "1px solid #A0AEC0",
                  fontWeight: "700",
                  borderRadius: "6px",
                }}
                className="btn btn-light"
                onClick={() => {
                  onPrevious();
                  setFileInfo(null);
                  setRecordedChunks([]);
                }}
              >
                Rekam Ulang
              </Button>
              <Button
                style={{
                  padding: "0.5rem 3.0rem",
                  fontWeight: "700",
                  borderRadius: "6px",
                }}
                className="btn btn-success"
                onClick={() => {
                  openModalStartTrial();
                }}
              >
                Mulai Trial Interview
              </Button>
            </div>
          </>
        )}
      </div>

      <Modal
        isOpen={modalStartTrial}
        toggle={() => {
          openModalStartTrial();
        }}
        centered
      >
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img style={{ marginTop: "15px" }} src={FotoModal} />
          <h5 style={{ textAlign: "center", marginTop: "30px" }}>
            Anda Akan Memulai Trial Interview
          </h5>
          <p style={{ padding: "0 70px", textAlign: "center" }}>
            Sebelum melakukan interview yang sebenarnya, Anda dipersilakan
            melakukan trial interview terlebih dahulu.{" "}
            <span style={{ fontWeight: "800" }}>
              Pastikan perangkat dalam kondisi baik.
            </span>
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "300px",
              marginTop: "10px",
            }}
          >
            <Button
              style={{
                padding: "0.5rem 3.0rem",
                backgroundColor: "#ffffff",
                color: "#718096",
                border: "1px solid #A0AEC0",
                fontWeight: "700",
                borderRadius: "6px",
              }}
              className="btn btn-light"
              onClick={() => {
                setModalStartTrial(false);
              }}
            >
              Tutup
            </Button>
            <Button
              style={{
                padding: "0.5rem 3.0rem",
                fontWeight: "700",
                borderRadius: "6px",
              }}
              className="btn btn-success"
              onClick={() => {
                window.location.href = "/trial";
              }}
            >
              Mulai
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
         <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
           <img src={FotoModal} />
           <h5 style={{textAlign: 'center', marginTop: '30px'}}>Apakah Anda Sudah Siap?</h5>
           <p style={{padding: '0 70px', textAlign: 'center'}}>Pastikan kembali perangkat yang Anda gunakan terhubung dengan jaringan internet.</p>
         </ModalBody>
         <ModalFooter style={{justifyContent: 'space-evenly'}}>
             <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
             <a  type="button" className="btn btn-success" id="edit-btn" href="/interview"  onClick={() => {videoUploader()}}>Mulai</a>
         </ModalFooter>
       </Modal> */}
    </React.Fragment>
  );
};

export default P4;
