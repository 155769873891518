import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState === "I1") {
      history.push("/i1");
    }
    if (iscurrentState === "I2") {
      history.push("/i2");
    }
    if (iscurrentState === "I3") {
      history.push("/i3");
    }
  }, [history, iscurrentState]);

  const menuItems = [
    {
      id: "i1",
      label: "Lol",
      icon: "las la-exclamation-triangle",
      link: "/i1",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("I1");
      },
    },
    {
      id: "i2",
      label: "Petunjuk Pengoperasian",
      icon: "las la-question-circle",
      link: "/i2",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("I2");
      },
    },
    {
      id: "i3",
      label: "Trial",
      icon: " las la-sync",
      link: "/i3",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("I3");
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
